import React, { useState, useRef } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { ErrorMessage, Formik, Form as FormFormik } from "formik";
import { sigapFormSchema, tipoArquivos } from "../form.schema";
import { BsCheck, BsExclamationTriangleFill, BsFillXCircleFill, BsQuestionCircle } from "react-icons/bs";
import { resolveErrors } from "../../../../utils/errorTypes";

import _ from "lodash";
import Button from "../../../../components/Button";
import { toast } from "../../../../components/Toast";
import Trigger from "../../../../components/OverlayTrigger";
import { Modal, Table } from "@themesberg/react-bootstrap";

const InputFileAccept = "text/xml,.xml";

const UploadCard = ({ name, sendService, sendTypeMessage, disabled, marcas, daily, monthly, fields = [], onSended = () => null }) => {
  const input = useRef(null);
  const [loading, setLoading] = useState(false);
  /** @type {[LoteResponse]} */
  const [showSuccessModal, setShowSuccessModal] = useState(null);

  const onSubmit = async (formValues, { resetForm }) => {

    setLoading(true);
    const field = _.kebabCase(name);
    const { files, ...rest } = formValues;

    try {
      const res = await sendService({ ...rest, [field]: files, loteName: name });
      onSended();
      resetForm();
      if (input && input.current) input.current.value = '';
      toast(`Lote de ${name}, enviado com sucesso!`, "success");
      setShowSuccessModal(res?.data?.data?.sigapResponse);
    } catch (err) {
      toast(resolveErrors(err?.response?.data?.errors, `Error ao enviar ${name}`), "error");
    } finally {
      setLoading(false);
    }
  }

  const onClearFile = (file, files, setFieldValue) => {
    const newValue = state => {
      if (file && (state && state.length > 1)) return Array.from(state).filter(e => e.name !== file?.name)
      else {
        if (input && input.current) input.current.value = '';
        return [];
      }
    }
    setFieldValue('files', newValue(files));
  }

  return (<>
    <Formik
      initialValues={{
        tipoArquivo: 1,
        justificativaRetificacao: '',
        sigapOperatorId: '',
        files: [],
        signXml: true
      }}
      validationSchema={sigapFormSchema(fields)}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ values, errors, isValid, dirty, handleChange, handleSubmit, setFieldValue }) => {
        // console.log({ values, errors })
        return (
          <Col className="my-2 col-md-4 col-12 mb-4">
            <FormFormik className="upload-card p-3 border rounded-lg h-100">

              <div className="d-grid mb-3">
                <Form.Label className="h5 mb-3">
                  <div className="mb-2">{name}</div>
                  <div className="d-flex gap-2 align-items-center">
                    <span data-badge={daily ? "success" : "warning"}>{daily ? "Diário" : "Mensal"}</span>
                    <span data-badge={'disabled'}>{sendTypeMessage}</span>
                  </div>
                </Form.Label>
                <Col className="mb-3">
                  <Form.Label className="d-flex">
                    Tipo de Arquivo
                    <Trigger placement='top' message={"Indica se o arquivo é original, retificador ou correção. Só poderá haver um Tipo de Arquivo retificador se houver um arquivo anterior original transmitido e validado. "}>
                      <span className="ms-auto"><BsQuestionCircle /></span>
                    </Trigger>
                  </Form.Label>
                  <Form.Select
                    name="tipoArquivo"
                    onChange={({ target: { value } }) => { setFieldValue("tipoArquivo", Number(value)) }}
                    id={name}
                    value={values.tipoArquivo || ""}
                  >
                    <option disabled selected value={''}>Selecione o tipo de arquivo</option>
                    {tipoArquivos.map(({ value, label }, key) => {
                      return <option key={key} value={value}>{label}</option>
                    })}
                  </Form.Select>
                  <ErrorMessage
                    component="small"
                    name={"tipoArquivo"}
                    className="text-danger"
                  />
                </Col>

                {values.tipoArquivo == 2 && <Col className="mb-3">
                  <Form.Label className="d-flex">
                    Justificativa da Retificação
                    <Trigger placement='top' message={"Em caso de arquivo retificador, encaminhar a justificativa para a retificação do arquivo. O arquivo retificado deverá ser utilizado apenas nos casos de erro no arquivo. "}>
                      <span className="ms-auto"><BsQuestionCircle /></span>
                    </Trigger>
                  </Form.Label>
                  <Form.Control
                    as={'textarea'}
                    rows={3}
                    name="justificativaRetificacao"
                    onChange={handleChange}
                    id={name}
                    value={values.justificativaRetificacao || ""}
                  />
                  <ErrorMessage
                    component="small"
                    name={"justificativaRetificacao"}
                    className="text-danger"
                  />
                </Col>}
                {fields.includes('sigapOperatorId') ?
                  <Col className="mb-3">
                    <Form.Label>Marca</Form.Label>
                    <Form.Select
                      name="sigapOperatorId"
                      onChange={({ target: { value } }) => { setFieldValue("sigapOperatorId", Number(value)) }}
                      id={name}
                      value={values.sigapOperatorId || ""}
                    >
                      <option disabled selected value={''}>Selecione a Marca</option>
                      {marcas?.result?.map(({ name, sigapOperatorId }, key) => {
                        return <option key={key} value={sigapOperatorId}>{name}</option>
                      })}
                    </Form.Select>
                    <ErrorMessage
                      component="small"
                      name={"sigapOperatorId"}
                      className="text-danger"
                    />
                  </Col>
                  : null}
              </div>

              <Col className="mb-3 h-100 d-grid">
                <Form.Control
                  name={"files"}
                  _ref={input}
                  ref={input}
                  accept={InputFileAccept}
                  onChange={({ target }) => setFieldValue('files', target.files)}
                  id={String(name).toLowerCase()}
                  type="file"
                  style={{ width: 0, height: 0, opacity: 0, position: 'absolute' }}
                  disabled={disabled}
                // multiple
                />
                <Form.Label className="input-file-label mb-0" disabled={disabled} htmlFor={String(name).toLowerCase()} data-label={`Faça o upload dos aquivos XML para ${name}`} />
                {values.files ? Array.from(values.files).map((file, key) => {
                  return (
                    <Form.Control.Feedback className="d-flex justify-content-between">
                      <span className="line-clamp"><BsCheck /> {file.name}</span>
                      <BsFillXCircleFill className="text-danger cursor-pointer flex-shrink-0" onClick={() => onClearFile(file, values.files, setFieldValue)} />
                    </Form.Control.Feedback>
                  )
                })
                  : null}
                <ErrorMessage
                  component="small"
                  name={"files"}
                  className="text-danger"
                />
              </Col>

              <Col>
                <Col className="mt-2">
                  <Form.Check
                    id={`signXML:${name}`}
                    name={"signXml"}
                    checked={values.signXml}
                    label="Assinar arquivos XMLs"
                    onChange={({ target: { checked } }) => { setFieldValue("signXml", checked) }}
                  />
                </Col>

                <Button disabled={!dirty} onClick={handleSubmit} loading={loading} className="mt-2 w-100" type='button'>Enviar</Button>
              </Col>
            </FormFormik>
          </Col>

        )
      }}
    </Formik>
    <Modal show={Boolean(showSuccessModal)} centered>
      <Modal.Header className="justify-content-center">{showSuccessModal?.mensagem}</Modal.Header>
      <Modal.Body>
        <Table striped responsive hover size='sm'>
          <tbody>
            <tr>
              <td>Data do Lote:</td>
              <td>{showSuccessModal?.dataLote}</td>
            </tr>
            <tr>
              <td>Tipo do Lote:</td>
              <td><span data-badge={'info'} className="w-100">{showSuccessModal?.tipoLote}</span></td>
            </tr>
            <tr>
              <td>Tipo do Arquivo:</td>
              <td><span data-badge={showSuccessModal?.tipoArquivo} className="w-100">{showSuccessModal?.tipoArquivo}</span></td>
            </tr>
            <tr>
              <td>Tipo do Recibo:</td>
              <td><span data-badge={'success'} className="w-100">{showSuccessModal?.tipoRecibo}</span></td>
            </tr>
            <tr>
              <td>Periodicidade:</td>
              <td><span data-badge={showSuccessModal?.periodicidade} className="w-100">{showSuccessModal?.periodicidade}</span></td>
            </tr>
            <tr>
              <td>Data de Recebimento:</td>
              <td>{showSuccessModal?.dataRecebimento}</td>
            </tr>
            <tr>
              <td>Situação da Recepção:</td>
              <td><span data-badge={showSuccessModal?.situacaoRecepcao} className="w-100">{showSuccessModal?.situacaoRecepcao}</span></td>
            </tr>
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => setShowSuccessModal(null)}>Ok</Button>
      </Modal.Footer>
    </Modal>
  </>)
}

export const SigapSendLotesFiles = ({ dataFileItems, marcas }) => {

  return (<>
    <div className="FormsBg p-3 mt-4">
      <div className="section-header-wrap m-0 py-2 p-0">
        <h2 className="title-text">Sigap - Envio de lotes XML</h2>
      </div>
      <div className="mt-3 alert p-1 px-2 alert-warning">
        <p className="mb-0">
          <small className="d-flex align-items-center gap-1">
            <BsExclamationTriangleFill /> Os lotes de arquivos <strong>(Diários)</strong> devem ser enviados até às 06 horas do dia imediatamente seguinte ao de referência.
          </small>
        </p>
        <p className="mt-1 mb-0">
          <small className="d-flex align-items-center gap-1">
            <BsExclamationTriangleFill /> Os lotes de arquivos <strong>Operador (Mensal)</strong> devem ser enviados até o dia 5 (cinco) do mês seguinte.
          </small>
        </p>
      </div>
    </div>
    <div className="FormsBg p-4 mt-4">
      <Row>
        {dataFileItems.map((props, key) => <UploadCard key={key} {...props} marcas={marcas} />)}
      </Row>
    </div>
  </>)
};

/**
 * @typedef {{
*  tipoLote: string
*  chaveLote: string
*  idOperador: string
*  dataLote: string
*  numerolote: number
*  tipoArquivo: ORIGINAL | RETIFICACAO | CORRECAO
*  periodicidade: string
*  dataGeracao: string
*  dataRecebimento: string
*  tipoRecibo: string
*  situacaoRecepcao: 'OK' | 'NOK'
*  codMensagem: number
*  mensagem: string
*  detalhes: string[]
*  loteXmmlGzipB64: string
*  loteFinal: number
* }} LoteResponse
*/

export default SigapSendLotesFiles;