import { ErrorMessage, Formik, Form as FormFormik } from "formik";
import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Button from "../../../../components/Button";
import { SigapMovimentoSearchForm, SigapMovimentoSearchInitialValues } from "../form.schema";
import { resolveErrors } from "../../../../utils/errorTypes";
import { sigapService } from "../../../../utils/apiCalls";
import { toast } from "../../../../components/Toast";
import DateRangePicker from "../../../../components/DateRangePicker";
import { formatDateYMD, getDateDaysAgo } from "../../../../utils/dateFormatter";
import { Table } from "@themesberg/react-bootstrap";
import FormatDate from "../../../../utils/formatDate";

export const SigapMovimentoSearch = ({ marcas }) => {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);

  const initialRangeDate = (reset) => [{
    startDate: getDateDaysAgo(1),
    endDate: getDateDaysAgo(1),
    key: 'selection'
  }];

  const [dateRange, setDateRange] = useState(initialRangeDate);

  const onSubmit = async (formValues) => {
    setLoading(true);
    try {
      const res = await sigapService.getMovimentoSearch({
        ...formValues,
        dataReferenciaInicio: formatDateYMD(dateRange.map(a => a.startDate)),
        dataReferenciaFim: formatDateYMD(dateRange.map(a => a.endDate)),
      });
      toast("Sucesso!", "success");
      setResult(res.data?.data?.result);
    } catch (err) {
      toast(resolveErrors(err?.response?.data?.errors, `Error ao obter resultados`), "error");
    } finally {
      setLoading(false);
    }
  }

  return (<>
    <div className="TableBg mt-4 pt-1">
      <div className="section-header-wrap m-0 mt-2 py-3 px-3">
        <h2 className="title-text">Operação para recuperação do status de envio realizado pela operadora</h2>
      </div>
      <div className='position-relative h-100 p-3'>
        <Formik
          initialValues={SigapMovimentoSearchInitialValues}
          validationSchema={SigapMovimentoSearchForm()}
          onSubmit={onSubmit}
        >
          {({ values, handleChange, setFieldValue, handleSubmit, is }) => {
            return (
              <FormFormik autoComplete="off">
                <Row>
                  <Col xs={12} md={6} className="mb-3">
                    <Form.Label>Marca</Form.Label>
                    <Form.Select
                      name="sigapOperatorId"
                      onChange={handleChange}
                      value={values.sigapOperatorId || ""}
                    >
                      <option disabled selected value={''}>Selecione a Marca</option>
                      {marcas?.result?.map(({ name, sigapOperatorId }, key) => {
                        return <option key={key} value={sigapOperatorId}>{name}</option>
                      })}
                    </Form.Select>
                    <ErrorMessage
                      component="small"
                      name={"sigapOperatorId"}
                      className="text-danger"
                    />
                  </Col>
                  <Col xs={12} md={6} className="mb-3">
                    <Form.Label>Data do lote</Form.Label>
                    <DateRangePicker
                      placement={'right'}
                      state={dateRange} setState={(e) => setDateRange(e.map(e => ({ ...e, startDate: e.startDate, endDate: e.startDate })))}
                      size='sm'
                    />
                  </Col>
                </Row>
                <Row>

                </Row>
                <Row>
                  <Col>
                    <Button className={"ms-auto d-block"} onClick={handleSubmit} loading={loading} >
                      Consultar
                    </Button>
                  </Col>
                </Row>
              </FormFormik>
            )
          }}
        </Formik>
      </div>
    </div>
    {result && <div className="TableBg mt-4 pt-1">
      <div className="section-header-wrap m-0 mt-2 py-3 px-3">
        <h2 className="title-text">Status</h2>
      </div>
      <Table striped responsive hover size='sm'>
        <tbody>
          <tr>
            <td>CNPJ:</td>
            <td>{result?.cnpj}</td>
          </tr>
          <tr>
            <td>Marca:</td>
            <td>{result?.marca}</td>
          </tr>
          <tr>
            <td>Data de Referência:</td>
            <td>{result?.dataReferencia}</td>
          </tr>
          <tr>
            <td>Situação:</td>
            <td>{result?.situacao}</td>
          </tr>
          <tr>
            <td>Data de Processamento:</td>
            <td>{FormatDate.toDateHour(result?.dataProcessamento)}</td>
          </tr>
        </tbody>
      </Table>
      <div className="section-header-wrap m-0 mt-2 py-3 px-3">
        <h2 className="title-text">Mensagens</h2>
      </div>
      <Table striped responsive hover size='sm'>
        <thead>
          <tr>
            <td>Código</td>
            <td>Descrição</td>
          </tr>
        </thead>
        <tbody>
          {result?.mensagens.map(e => {
            return (
              <tr>
                <td>{e.codigo}</td>
                <td>{e.descricao}</td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </div>}
  </>)
};

export default SigapMovimentoSearch;