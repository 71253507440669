import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import DateRangePicker from "../../../../components/DateRangePicker";
import Button from "../../../../components/Button";
import { formatDateYMD, getDateDaysAgo } from "../../../../utils/dateFormatter";
import { resolveErrors } from "../../../../utils/errorTypes";
import { downloadXmlFileFromString } from "../../../../utils/fileDownloader";
import { toast } from "../../../../components/Toast";

const DownloadXLMButton = ({ name, exportService, dateRange, disabled, exportWarning }) => {
  const [loading, setLoading] = useState(false);

  const handleExportXML = async (file) => {
    setLoading(true);
    try {
      /** @type {{ data: any[]}} */
      const res = await exportService({
        startDate: formatDateYMD(dateRange.map(a => a.startDate)),
        endDate: formatDateYMD(dateRange.map(a => a.endDate)),
      });
      if (Array.isArray(res.data)) {
        res.data.forEach((xml) => {
          downloadXmlFileFromString(name, xml);
        })
      }
    } catch (err) {
      toast(resolveErrors(err?.response?.data?.errors, `Error ao exportar ${name}`), "error");
    } finally {
      setLoading(false);
    }
  }

  return (
    <Col md={4} xs={12} className="my-4">
      <Form.Label className="h5">{name}</Form.Label>
      <Button disabled={disabled} variant="success" onClick={handleExportXML} loading={loading} className="mt-2 w-100">Exportar</Button>
      {exportWarning && <Form.Control.Feedback className="alert p-1 px-2 alert-warning d-block">{exportWarning}</Form.Control.Feedback>}
    </Col>
  )
}

export const SigapExportLotesFiles = ({ dataFileItems }) => {

  const initialRangeDate = (reset) => [{
    startDate: getDateDaysAgo(1),
    endDate: getDateDaysAgo(1),
    key: 'selection'
  }];

  const [dateRange, setDateRange] = useState(initialRangeDate);

  return (
    <div className="FormsBg p-3 mt-4">
      <div className="row section-header-wrap m-0 py-2 p-0">
        <Col md={9} xs={6} className="ps-0">
          <h2 className="title-text">Exportar arquivos XML</h2>
        </Col>
        <Col md={3} xs={6}>
          <DateRangePicker
            placement={'right'}
            state={dateRange} setState={(e) => setDateRange(e.map(e => ({ ...e, startDate: e.startDate, endDate: e.startDate })))}
            size='sm'
          />
        </Col>
      </div>
      <Row>
        {dataFileItems.map((props, key) => <DownloadXLMButton key={key} {...props} {...{ dateRange }} />)}
      </Row>
    </div>
  )
};

export default SigapExportLotesFiles;