import React, { useEffect, useState } from 'react';

import { Col, Row } from '@themesberg/react-bootstrap';

import { Formik, Form, ErrorMessage } from 'formik';
import { createCasinoGameFormInitialValues, createCasinoGameFormSchema } from './schema';
import Button from '../../../components/Button';
import InputsControl from '../../../components/Form/InputsControl';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCasinoProvidersStart, getSuperAdminAggregatorsStart, getSuperAdminGameCategoryStart, getSuperAdminGameSubCategoryStart } from '../../../store/redux-slices/superAdminCasinoManagement';
import { Loading } from '../../../components/Preloader';
import { createCasinoGame } from '../../../utils/apiCalls';
import { toast } from '../../../components/Toast';
import { resolveErrors } from '../../../utils/errorTypes';

export const CreateCasinoGamePage = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const { loading: fetchLoader, gameSubCategory, casinoProvidersData, aggregators, gameCategory } = useSelector(
    (state) => state.superAdminCasino
  );

  const onSubmit = async (formValues, { resetForm }) => {
    setLoading(true);
    try {
      await createCasinoGame(formValues);
      toast("Jogo criado com sucesso!", 'success');
      resetForm();
    } catch (err) {
      toast(resolveErrors(err?.response?.data?.errors, "Error ao criar jogo"), "error");
    } finally {
      setLoading(false);
    }
  }

  const fieldsConfig = [
    { label: "Nome", name: "name", type: "text", className: "col-sm-3 col-12" },
    { label: "Identificador", name: "identifier", type: "text", className: "col-sm-3 col-12" },
    { label: "Agregador", name: "gameAggregatorId", type: "select", className: "col-sm-3 col-12", options: InputsControl.CreateSelectOptions(aggregators?.rows, 'gameAggregatorId', 'name') },
    { label: "Provedor", name: "casinoProviderId", type: "select", className: "col-sm-3 col-12", options: InputsControl.CreateSelectOptions(casinoProvidersData?.rows, 'casinoProviderId', 'name') },
    {
      label: "Categoria", name: "gameCategoryId", type: "select", className: "col-sm-3 col-12",
      options: InputsControl.CreateSelectOptions(gameCategory?.rows, 'gameCategoryId', e => JSON.parse(e.name)['EN']),
      onChange: (gameCategoryId) => dispatch(getSuperAdminGameSubCategoryStart({ gameCategoryId })),
    },
    {
      label: "Sub-categoria", name: "gameSubCategoryId", type: "select", className: "col-sm-3 col-12",
      options: InputsControl.CreateSelectOptions(gameSubCategory?.rows, 'gameSubCategoryId', e => JSON.parse(e.name)['EN']),
    },

    { separator: true },

    { label: "Ativado", feedback: 'Status do jogo', name: 'isActive', type: "switch", className: "col-12 bg-gray-200-hover rounded-5" },
    { label: "Desktop", feedback: 'Possui versão Desktop?', name: 'devices.isDesktop', type: "switch", className: "col-12 bg-gray-200-hover rounded-5" },
    { label: "Mobile", feedback: 'Possui versão Mobile?', name: 'devices.isMobile', type: "switch", className: "col-12 bg-gray-200-hover rounded-5" },
    { label: "Demo", feedback: "Jogo possui versão demo?", name: 'demo', type: "switch", className: "col-12 bg-gray-200-hover rounded-5" },
    { label: "Freespins", feedback: "Jogo possui giros grátis?", name: 'hasFreespins', type: "switch", className: "col-12 bg-gray-200-hover rounded-5" },

    { label: "Thumbnail", name: 'thumbnail', type: "upload-image", className: "col-12" },
  ];

  useEffect(() => {
    if (!casinoProvidersData) dispatch(getAllCasinoProvidersStart({}));
    if (!aggregators) dispatch(getSuperAdminAggregatorsStart({}));
    if (!gameCategory) dispatch(getSuperAdminGameCategoryStart({}));
    if (!gameSubCategory) dispatch(getSuperAdminGameSubCategoryStart({ gameCategoryId: createCasinoGameFormInitialValues.gameCategoryId }));
  }, [])


  return (<>
    <div className="section-header-wrap mt-n3 mx-n3">
      <Row>
        <Col lg={8} xs={7}>
          <h2 className="title-text">Adicionar Jogo</h2>
        </Col>
      </Row>
    </div>
    <div className="FormsBg position-relative">
      <Formik
        initialValues={createCasinoGameFormInitialValues}
        validationSchema={createCasinoGameFormSchema}
        onSubmit={onSubmit}
      >
        {({ values, errors, handleChange, handleSubmit, setFieldValue }) => {

          return (
            <Form autoComplete='off'>
              <Row>
                <InputsControl {...{ fieldsConfig, handleChange, setFieldValue, values }} />
              </Row>

              <Col xs={12} className='mt-3 w-100 d-flex justify-content-end'>
                <Button onClick={handleSubmit} loading={loading}>Salvar</Button>
              </Col>
            </Form>
          )
        }}
      </Formik>
      {fetchLoader ? <Loading className={"position-absolute bg-white top-0 start-0 rounded-4 w-100 h-100 z-9"} /> : null}
    </div>
  </>);
}
