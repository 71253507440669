import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Loading } from "../../../../components/Preloader";
import PaginationComponent from "../../../../components/Pagination";
import FormatDate from "../../../../utils/formatDate";
import { downloadFile } from "../../../../utils/fileDownloader";
import { sigapService } from "../../../../utils/apiCalls";
import { resolveErrors } from "../../../../utils/errorTypes";
import { toast } from "../../../../components/Toast";
import { tableHeaders } from "../form.schema";

export const SigapLotesReportTable = () => {
  const [data, setData] = useState({});
  const [initialLoading, setInitialLoading] = useState(true);
  const [page, setPage] = useState(1);

  const getLastLotesSent = async ({ initialLoading, loading, page = 1 }) => {
    if (initialLoading || loading) setInitialLoading(true);
    try {
      const res = await sigapService.getSigapLotesReport({ limit: 7, pageNo: page });
      setData(res.data.data);
    } catch (err) {
      toast(resolveErrors(err?.response?.data?.errors, `Error ao obter relatório de últimos envios`), "error");
    } finally {
      setInitialLoading(false)
    }
  }

  useEffect(() => {
    getLastLotesSent({ initialLoading: true });
  }, []);

  return (
    <div className="TableBg mt-4 pt-1">
      <div className="section-header-wrap m-0 mt-2 py-3 px-3">
        <h2 className="title-text">Últimos envios</h2>
      </div>
      <div className='position-relative h-100'>
        <Table striped responsive hover size="sm" className="text-center mt-3">
          <thead className="thead-light">
            <tr>
              {tableHeaders.map((h, idx) => (
                <th key={idx} className={h.className}>{h.label}</th>
              ))}
            </tr>
          </thead>

          <tbody >
            {(!initialLoading) &&
              data?.result?.map(
                ({
                  sigapLoteReportId,
                  loteName,
                  loteFileName,
                  loteFileUrl,
                  operator,
                  details,
                  actionee,
                  createdAt,
                }) => {
                  return (
                    <tr key={sigapLoteReportId}>
                      <td>{sigapLoteReportId}</td>
                      <td className="text-left">{operator?.name}</td>
                      <td className="text-left">{details?.tipoLote ?? loteName}</td>
                      <td>{details?.tipoArquivo && <span data-badge={details?.tipoArquivo}>{details?.tipoArquivo}</span>}</td>
                      <td>{details?.tipoRecibo && <span data-badge={'success'}>{details?.tipoRecibo}</span>}</td>
                      <td>{details?.tipoArquivo && <span data-badge={details?.periodicidade}>{details?.periodicidade}</span>}</td>
                      <td>{FormatDate.toDayMonthYear(details?.dataLote)}</td>
                      <td className="text-left text-link cursor-pointer" onClick={() => downloadFile(loteFileUrl, 'Baixado com sucesso!')}>{loteFileName}</td>
                      <td>{actionee?.email}</td>
                      <td>{FormatDate.toDateHour(createdAt)}</td>
                    </tr>
                  );
                }
              )}

            {data?.result?.length === 0 && (
              <tr>
                <td colSpan={13} className="text-danger text-center">
                  Sem dados
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        {(initialLoading) ? <Loading className={"position-absolute bg-white top-0 w-100 h-100"} /> : null}
      </div>
      {<div className='row px-3 mt-3'>
        <div className='col-sm-6 col-12 mb-3 mb-sm-0 justify-content-end justify-content-sm-start  d-flex align-items-center'>
          Total de registros ({data?.total || 0})
        </div>
        <div className='col-sm-6 col-12 d-flex justify-content-end'>
          {data?.total !== 0 && !initialLoading && (
            <PaginationComponent
              className=""
              page={data?.total < page ? setPage(1) : page}
              totalPages={data?.totalPages}
              setPage={(page) => {
                setPage(page);
                getLastLotesSent({ loading: true, page });
              }}
            />
          )}
        </div>
      </div>}
    </div>
  )
};

export default SigapLotesReportTable;