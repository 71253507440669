import React, { useEffect, useState } from 'react';
import { Row, Col } from '@themesberg/react-bootstrap';
import { Loading } from '../../../../components/Preloader';
import { Form, Formik } from 'formik';
import { globalSettingsFields, globalSettingsFormInitialValues, globalSettingsFormSchema } from './config.schema';
import { resolveErrors } from '../../../../utils/errorTypes';
import { toast } from '../../../../components/Toast';
import { getGlobalSettingsService, updateGlobalSettingsService } from '../../../../utils/apiCalls';
import Button from '../../../../components/Button';
import { GLOBAL_SETTINGS_KEY } from '../../../../utils/constant';
import { useDispatch } from 'react-redux';
import InputsControl from '../../../../components/Form/InputsControl';


const GeneralSettingsPage = () => {
  const dispatch = useDispatch();

  const [initialLoading, setInitialLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initialFormValues, setInitialFormValues] = useState(null);

  const loadGlobalSettings = async () => {
    setInitialLoading(true);
    try {
      const { data: { data } } = await getGlobalSettingsService({ keys: [GLOBAL_SETTINGS_KEY.ADMIN_INFORMATION, GLOBAL_SETTINGS_KEY.SITE_INFORMATION, GLOBAL_SETTINGS_KEY.SENDGRID] });
      setInitialFormValues(globalSettingsFormInitialValues(data.settings));
    } catch (err) {
      const message = resolveErrors(err.response.data.errors, "Erro ao obter configurações de transações de pagamento");
      toast(message, "error");
    } finally {
      setInitialLoading(false);
    }
  }

  const onSubmit = async (formValue) => {
    setLoading(true);
    try {
      await updateGlobalSettingsService({ ...formValue });
      // dispatch(getAdminPanelSettingsStart());

      toast("Alterações salvas com sucesso!", "success");
    } catch (err) {
      const message = resolveErrors(err.response.data.errors, "Erro ao salvar as alterações");
      toast(message, "error");
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    loadGlobalSettings();
  }, [])

  return (
    <>
      <div className='section-header-wrap mt-n3 mx-n3'>
        <h2 className='title-text'>Configurações Gerais</h2>
      </div>

      <div className='FormsBg position-relative'>
        <Formik
          enableReinitialize
          initialValues={initialFormValues}
          validationSchema={globalSettingsFormSchema}
          onSubmit={onSubmit}
        >
          {({ values, errors, handleChange, handleSubmit, isValid, setFieldValue }, key) => {
            // console.log(values, errors)
            return (
              <Form autoComplete='off' key={key}>
                <Row>
                  {!initialLoading ? <InputsControl {...{ fieldsConfig: globalSettingsFields, handleChange, setFieldValue, values, initialFormValues }} /> : null}
                </Row>
                <Row className='mt-4'>
                  <Col className='ms-auto col-sm-2 col-6'>
                    <Button
                      className='w-100'
                      disabled={!isValid}
                      onClick={handleSubmit}
                      loading={loading}
                    >
                      Salvar
                    </Button>
                  </Col>
                </Row>
              </Form>
            )
          }}
        </Formik>
        {initialLoading ? <Loading className={"position-absolute bg-white top-0 start-0 rounded-4 w-100 h-100"} /> : null}
      </div>

    </>
  )
}

export default GeneralSettingsPage;
