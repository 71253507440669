import { Reorder } from '@mui/icons-material'
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Table
} from '@themesberg/react-bootstrap'
import React from 'react'
import ConfirmationModal from '../../../components/ConfirmationModal'
import Trigger from '../../../components/OverlayTrigger'
import PaginationComponent from '../../../components/Pagination'
import ProviderFilter from '../../../components/ProviderFilter'
import { AdminsRoutes } from '../../../routes'
import useCheckPermission from '../../../utils/checkPermission'
import ActionMenu from './ActionMenu'
import CreateCasinoGames from './components/CreateCasinoGames'
import { tableHeaders } from './constants'
import useCasinoGamesListing from './hooks/useCasinoGamesListing'

export default () => {
  const {
    limit,
    page,
    selectedSubCategoryId,
    show,
    data,
    type,
    statusShow,
    setLimit,
    setPage,
    setSelectedSubCategoryId,
    setStatusShow,
    gameSubCategory,
    casinoGamesData,
    totalPages,
    handleStatusShow,
    handleYes,
    handleClose,
    handleShow,
    loading,
    getCategoryName,
    search,
    setSearch,
    active,
    setActive,
    status,
    getProviderName,
    navigate,
    setSelectedProvider,
    selectedProvider,
    createCategory,
    updateCategory,
    handleDeleteItems,
    name
  } = useCasinoGamesListing()
  const { isHidden } = useCheckPermission()

  return (

    <>
      <div className='section-header-wrap mt-n3 mx-n3'>
        <Row>
          <Col lg={8} xs={7}>
            <h2 className='title-text'>Casino Games</h2>
          </Col>
          <Col lg={4} xs={5}>
            <div className='d-flex gap-3 justify-content-end'>

              <Button
                variant='btn btn-success w-100'
                style={{ maxWidth: '140px' }}
                onClick={() => navigate(AdminsRoutes.CreateCasinoGame)}
                hidden={isHidden({ module: { key: 'CasinoManagement', value: 'U' } })}
              >
                <span>Adicionar Jogo</span>
              </Button>

              <Button
                variant='btn btn-primary w-100'
                style={{ maxWidth: '140px' }}
                onClick={() => navigate(AdminsRoutes.GameReorder)}
                hidden={isHidden({ module: { key: 'CasinoManagement', value: 'U' } })}
              >
                <Reorder className='me-2' />
                <span>Reordenar</span>
              </Button>
            </div>
          </Col>
        </Row>
      </div>

      <div className='FormsBg'>
        <Row>
          <Col xs={3}>
            <ProviderFilter
              selectedProvider={selectedProvider}
              setSelectedProvider={setSelectedProvider}
            />
          </Col>
          <Col xs={3}>
            <Form.Select
              onChange={(e) => {
                setSelectedSubCategoryId(e.target.value)
              }}
              value={selectedSubCategoryId || 'all'}
            >
              <option value=''>Sub Categoria</option>
              {gameSubCategory?.rows?.map(
                ({ gameSubCategoryId, jname }) => (
                  <option key={gameSubCategoryId} value={gameSubCategoryId}>
                    {jname?.EN}
                  </option>
                )
              )}
            </Form.Select>
          </Col>

          <Col xs={3}>

            <Form.Control
              type='search'
              value={search}
              placeholder='Pesquisar Nome'
              onChange={(event) => setSearch(event.target.value.replace(/[~`!$%^&*#=)()><?]+/g, ''))}
            />
          </Col>

          <Col xs={3}>
            <Form.Select
              onChange={(e) => { setActive(e.target.value) }}
              value={active}
            >
              <option value=''>Status</option>
              <option value='true'>Ativos</option>
              <option value='false'>Inativos</option>
            </Form.Select>
          </Col>

        </Row>
      </div>

      <div className='TableBg mt-4 pt-1'>

        <Table striped responsive hover size='sm' className='text-center mt-3'>
          <thead className='thead-light'>
            <tr>
              {tableHeaders.map((h, idx) => (
                <th
                  key={idx}
                  className={`${h.value === 'returnToPlayer' ? 'text-left' : ''}`}
                >
                  {h.label}
                </th>
              ))}
            </tr>
          </thead>

          <tbody id={loading ? 'cover-spin' : ''}>
            {!loading && Boolean(casinoGamesData) &&
              casinoGamesData?.rows?.map(
                (
                  {
                    name,
                    casinoGameId,
                    orderId,
                    thumbnailUrl,
                    casinoProviderId,
                    isActive,
                    parentId,
                    devices,
                    subCategoryName
                  },
                  indx
                ) => {
                  return (
                    <tr key={casinoGameId}>
                      <td>{casinoGameId}</td>
                      <td>{orderId}</td>
                      <td>
                        <Trigger message={name}>
                          <span
                            style={{
                              width: '100px',
                              cursor: 'pointer'
                            }}
                            className='d-inline-block text-truncate'
                          >
                            {name}
                          </span>
                        </Trigger>
                      </td>

                      <td className='text-capitalize'>{getProviderName(casinoProviderId)}</td>

                      {/* <td className='text-left'>{returnToPlayer}</td> */}

                      <td>{subCategoryName}</td>

                      <td>
                        <span
                          onClick={() => window.open(thumbnailUrl)}
                          className='text-link'
                          style={{ cursor: 'pointer' }}
                        >
                          View Here
                        </span>
                      </td>

                      <td className='text-capitalize'>
                        {devices?.isDesktop && 'Desktop'}
                        {devices?.isDesktop && devices?.isMobile && '&'}
                        {devices?.isMobile && 'Mobile'}
                      </td>

                      <td>
                        {isActive
                          ? (<span className='text-success'>Ativo</span>)
                          : (<span className='text-danger'>Inativo</span>)}
                      </td>

                      <td>
                        <ActionMenu
                          isHidden={isHidden}
                          handleShow={handleShow}
                          isActive={isActive}
                          name={name}
                          indx={indx}
                          handleStatusShow={handleStatusShow}
                          casinoGamesData={casinoGamesData}
                          casinoGameId={casinoGameId}
                          handleDeleteItems={handleDeleteItems}
                          navigate={navigate}
                          parentId={parentId}
                        />
                        {/* {(!isHidden({ module: { key: 'CasinoManagement', value: 'U' } }) || !isHidden({ module: { key: 'CasinoManagement', value: 'T' } }))
                                  ? (
                                    <ButtonGroup>
                                       <Trigger message="Edit">
                                      <Button
                                        className="m-1"
                                        size="sm"
                                        variant="warning"
                                        onClick={() =>
                                          handleShow(
                                            'Edit',
                                            casinoGamesData.rows[indx],
                                          )
                                        }
                                        hidden={isHidden({
                                          module: {
                                            key: 'CasinoManagement',
                                            value: 'U',
                                          },
                                        })}
                                      >
                                        <FontAwesomeIcon icon={faEdit} />
                                      </Button>
                                    </Trigger>

                                      {!isActive
                                        ? (
                                          <Trigger message='Set Status Active'>
                                            <Button
                                              className='m-1'
                                              size='sm'
                                              variant='success'
                                              onClick={() =>
                                                handleStatusShow(casinoGameId, isActive, name)}
                                              hidden={isHidden({ module: { key: 'CasinoManagement', value: 'T' } })}
                                            >
                                              <FontAwesomeIcon icon={faCheckSquare} />
                                            </Button>
                                          </Trigger>
                                          )
                                        : (
                                          <Trigger message='Set Status In-Active'>
                                            <Button
                                              className='m-1'
                                              size='sm'
                                              variant='danger'
                                              onClick={() =>
                                                handleStatusShow(casinoGameId, isActive, name)}
                                              hidden={isHidden({ module: { key: 'CasinoManagement', value: 'T' } })}
                                            >
                                              <FontAwesomeIcon icon={faWindowClose} />
                                            </Button>
                                          </Trigger>
                                          )}
                                          <Trigger message="Delete">
                                      <Button
                                        className="m-1"
                                        size="sm"
                                        variant="secondary"
                                        hidden={isHidden({
                                          module: {
                                            key: 'CasinoManagement',
                                            value: 'U',
                                          },
                                        })}
                                        onClick={() => handleDeleteItems()}
                                      >
                                        <FontAwesomeIcon icon={faTrash} />
                                      </Button>
                                    </Trigger>

                                      <Trigger message='View Blocked Countries'>
                                        <Button
                                          className='m-1'
                                          size='sm'
                                          variant='secondary'
                                          hidden={isHidden({ module: { key: 'CasinoManagement', value: 'U' } })}
                                          onClick={() => navigate(`/admin/casino-games/restrict-countries/${casinoGameId}`)}
                                        >
                                          <FontAwesomeIcon icon={faBan} />
                                        </Button>
                                      </Trigger>
                                    </ButtonGroup>
                                    )
                                  : 'NA'} */}
                      </td>
                    </tr>
                  )
                }
              )}

            {casinoGamesData?.count === 0 && !loading &&
              (
                <tr>
                  <td
                    colSpan={8}
                    className='text-danger text-center'
                  >
                    No data found
                  </td>
                </tr>
              )}
          </tbody>
        </Table>
        {casinoGamesData?.count !== 0 && !loading &&
          (
            <PaginationComponent
              page={casinoGamesData?.count < page ? setPage(1) : page}
              totalPages={totalPages}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
            />
          )}
      </div>

      <ConfirmationModal
        setShow={setStatusShow}
        show={statusShow}
        handleYes={handleYes}
        active={status}
        name={name}
      />
      <Modal
        show={show}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{type} Casino Game</Modal.Title>
        </Modal.Header>

        <CreateCasinoGames
          handleClose={handleClose}
          data={data}
          limit={limit}
          pageNo={page}
          gameSubCategory={gameSubCategory}
          createProvider={createCategory}
          updateProvider={updateCategory}
        />
      </Modal>
    </>
  )
}
