import { deleteRequest, getRequest, postRequest, putRequest } from "./axios";
import { GLOBAL_SETTINGS_KEY } from "./constant";
import { safeStringify } from "./helpers";
import { serialize } from 'object-to-formdata'

const { REACT_APP_API_URL } = process.env;

const superAdminLogin = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/admin/login`, data);

const getAllAdmins = (payload) =>
  getRequest(`${REACT_APP_API_URL}/api/admin?${new URLSearchParams(payload)}`);

const getAllCurrencies = ({ limit, pageNo }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/currency?limit=${limit}&pageNo=${pageNo}`
  );

const getAllAffiliates = (payload) =>
  getRequest(
    `${REACT_APP_API_URL}/api/streamer/all?${new URLSearchParams(payload)}`
  );

const getAffiliateById = ({ affiliateId }) =>
  getRequest(`${REACT_APP_API_URL}/api/streamer?affiliateId=${affiliateId}`);

const getAllCms = ({ pageNo, limit, search, isActive }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/cms?pageNo=${pageNo}&limit=${limit}&search=${search}&isActive=${isActive}`
  );

const getCmsByPageId = ({ cmsPageId }) =>
  getRequest(`${REACT_APP_API_URL}/api/cms/details?cmsPageId=${cmsPageId}`);

const getAllUsers = (payload) =>
  getRequest(
    `${REACT_APP_API_URL}/api/user/all?${new URLSearchParams(payload).toString()}`
  );

const getAllCasinoProviders = ({ limit = '', pageNo = '', search = '' }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/casino/provider?limit=${limit}&pageNo=${pageNo}&search=${search}`
  );

const getAllCasinoGames = ({
  bonusId,
  limit,
  pageNo,
  casinoCategoryId,
  search,
  isActive,
  selectedProvider,
  freespins,
  addGame,
  gameSubCategoryId,
  reorder,
}) =>
  getRequest(
    `${REACT_APP_API_URL}/api/casino/games?limit=${limit}&pageNo=${pageNo}&casinoCategoryId=${casinoCategoryId}&search=${search}&isActive=${isActive}&providerId=${selectedProvider}&freespins=${freespins}&bonusId=${bonusId}&addGames=${addGame}&gameSubCategoryId=${gameSubCategoryId}&reorder=${reorder}`
  );

const createCurrency = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/currency`, data);

const editCurrency = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/currency`, data);

const getCurrencyById = (currencyId) =>
  getRequest(
    `${REACT_APP_API_URL}/api/currency/details?currencyId=${currencyId}`
  );

const getAdminRole = () => getRequest(`${REACT_APP_API_URL}/api/admin/roles`);

const getAdmin = ({ adminId }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/admin/details?adminUserId=${adminId && adminId}`
  );
const getUser = ({ userId }) =>
  getRequest(`${REACT_APP_API_URL}/api/user?userId=${userId}`);

// const getAdminRoles = () =>
//   getRequest(`${REACT_APP_API_URL}/api/tenant/admin/roles`)

const getAdminUsers = (limit, pageNo, sort, roleId) =>
  getRequest(
    `${REACT_APP_API_URL}/api/tenant/admin?orderBy=email&limit=${limit}&pageNo=${pageNo}&sort=${sort}&adminRoleId=${roleId}`
  );

const getAdminUserDetails = ({ adminUserId, isTenant }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/${isTenant ? "tenant/admin/details" : "admin/tenant/admin-details"
    }?adminUserId=${adminUserId}`
  );

const createCms = ({ data }) =>
  postRequest(`${REACT_APP_API_URL}/api/cms`, data);

const getSuperAdminWallet = () =>
  getRequest(`${REACT_APP_API_URL}/api/admin/wallets`);

const getMasterGames = ({
  bonusId,
  limit,
  pageNo,
  search,
  casinoCategoryId,
  providerId,
  freespins,
}) =>
  getRequest(
    `${REACT_APP_API_URL}/api/tenant/casino/games?limit=${limit}&pageNo=${pageNo}&search=${search}&casinoCategoryId=${casinoCategoryId}&providerId=${providerId}&freespins=${freespins}&bonusId=${bonusId}&isActive=true`
  );

const createTenantCasinoCategory = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/tenant/casino/category`, data);

const addGamesToSubCategory = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/casino/category-games`, data);

const createTenantCasinoSubCategory = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/tenant/casino/sub-category`, data);

const updateTenantCasinoSubCategory = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/tenant/casino/sub-category`, data);

const updateSubCategoryReOrder = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/casino/order-sub-category`, data);

const updateCategoryReOrder = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/casino/order-category`, data);

const getTenantCasinoMenuById = ({ casinoMenuId }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/tenant/casino/menu?casinoMenuId=${casinoMenuId}`
  );

const updateTenantCasinoCategory = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/tenant/casino/category`, data);

const getAllTenantCasinoMenuItems = ({ limit, pageNo, type, isActive }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/tenant/menu-items?limit=${limit}&pageNo=${pageNo}&type=${type}&isActive=${isActive}`
  );

const getCountries = ({ limit, pageNo, name, isActive, kycMethod = "" }) => {
  if (name) {
    return getRequest(
      // `${REACT_APP_API_URL}/api/country/list?limit=${limit}&pageNo=${pageNo}&name=${name}&kycMethod=${kycMethod}`
      `${REACT_APP_API_URL}/api/country/list?${new URLSearchParams({
        limit,
        pageNo,
        name,
        kycMethod,
      })}`
    );
  } else {
    return getRequest(
      `${REACT_APP_API_URL}/api/country/list?${new URLSearchParams({
        limit,
        pageNo,
        kycMethod,
      })}`
    );
  }
};

const getRestrictedItems = ({ limit, pageNo, type, countryId, search }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/country/restricted-items?limit=${limit}&pageNo=${pageNo}&type=${type}&countryId=${countryId}&search=${search}`
  );

const getUnRestrictedItems = ({ limit, pageNo, type, countryId, search }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/country/unrestricted-items?limit=${limit}&pageNo=${pageNo}&type=${type}&countryId=${countryId}&search=${search}`
  );

const addRestrictedItems = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/country/restricted`, data);

const updateSuperAdminUser = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/admin/`, data);

const createSuperAdminUser = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/admin/`, data);

const createCasinoGame = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/casino/game`, serialize(data));

const createCasinoProvider = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/casino/provider`, data);

const createCasinoCategory = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/casino/category`, data);

const createCasinoSubCategory = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/casino/sub-category`, data);

const updateCasinoGame = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/casino/game`, data);

const updateCasinoCategory = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/casino/category`, data);

const updateCasinoSubCategory = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/casino/sub-category`, data);

const updateCasinoProvider = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/casino/provider`, data);

const getSuperAdminDetails = () =>
  getRequest(`${REACT_APP_API_URL}/api/admin/details`);

const createTenantCasinoMenuItem = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/tenant/menu-item`, data);

const getAllWageringCasinoGames = ({ providerId, search }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/casino/all-games?providerId=${providerId}&search=${search}`
  );

const updateTenantCasinoMenuItem = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/tenant/menu-item`, data);

const updateTenantAdmin = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/admin/tenant/admin`, data);

const getAllSuperAdminTransactions = ({
  limit,
  pageNo,
  search,
  startDate,
  endDate,
  currencyId,
  transactionType,
  paymentProvider,
  isUserDetail,
  userId,
  status,
}) =>
  getRequest(
    `${REACT_APP_API_URL}/api/admin/transactions?limit=${limit}&pageNo=${pageNo}&search=${search}&status=${status}&startDate=${startDate}&endDate=${endDate}&currencyCode=${currencyId}&transactionType=${transactionType}&paymentProvider=${paymentProvider}${isUserDetail ? `&userId=${userId}` : ""
    }`
  );

const getSuperAdminTransactionUsers = ({ email }) =>
  getRequest(`${REACT_APP_API_URL}/api/admin/transaction-users?email=${email}`);

const getSuperAdminAggregators = ({ limit = '', pageNo = '' }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/casino/aggregator?limit=${limit}&pageNo=${pageNo}`
  );

const createSuperAdminAggregator = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/casino/aggregator`, data);

const getSuperAdminGameCategory = ({ limit = '', pageNo = '', search = '' }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/casino/category?limit=${limit}&pageNo=${pageNo}&search=${search}`
  );

const getSuperAdminGameSubCategory = ({
  limit = '',
  pageNo = '',
  search = '',
  gameCategoryId = '',
  isActive = '',
}) =>
  getRequest(
    `${REACT_APP_API_URL}/api/casino/sub-category?pageNo=${pageNo}&gameCategoryId=${gameCategoryId}&search=${search}&limit=${limit}&isActive=${isActive}`
  );

const deleteRestrictedItems = (data) =>
  deleteRequest(`${REACT_APP_API_URL}/api/country/restricted-items`, data);

const getRestrictedCountries = ({ itemId, type, limit, pageNo }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/country/restricted?itemId=${itemId}&type=${type}&limit=${limit}&pageNo=${pageNo}`
  );

const getUnRestrictedCountries = ({ itemId, type, limit, pageNo, search }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/country/unrestricted?itemId=${itemId}&type=${type}&limit=${limit}&pageNo=${pageNo}&search=${search}`
  );

const deleteRestrictedCountries = (data) =>
  deleteRequest(`${REACT_APP_API_URL}/api/country/restricted`, data);

const addRestrictedCountries = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/country/restricted-items`, data);

const addDepositToOther = ({ data }) =>
  putRequest(`${REACT_APP_API_URL}/api/admin/add-balance`, data);

const manageAffiliateMoney = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/admin/manage-affiliate-money`, data);

const getSuperAdminCasinoTransactions = ({
  limit,
  pageNo,
  startDate,
  endDate,
  currencyCode,
  transactionType,
  tenantId,
  status,
  email,
  adminId,
  userId,
}) =>
  getRequest(
    `${REACT_APP_API_URL}/api/casino/transactions?limit=${limit}&pageNo=${pageNo}&startDate=${startDate}&endDate=${endDate}&currencyCode=${currencyCode}&transactionType=${transactionType}&tenantId=${tenantId}&status=${status}&email=${email}&adminId=${adminId}&userId=${userId}`
  );

const getSuperAdminSportsTransactions = ({
  limit,
  pageNo,
  startDate,
  endDate,
  currencyCode,
  transactionType,
  status,
  email,
  userId,
  actionType,
}) =>
  getRequest(
    `${REACT_APP_API_URL}/api/sportsbook/transactions?limit=${limit}&pageNo=${pageNo}&startDate=${startDate}&endDate=${endDate}&currencyCode=${currencyCode}&transactionType=${transactionType}&email=${email}&userId=${userId}&actionType=${actionType}`
  );

const getAllClients = ({ orderBy, limit, pageNo, sort, search, status }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/admin/tenant/admin-users?orderBy=${orderBy}&limit=${limit}&pageNo=${pageNo}&sort=${sort}&search=${search}&status=${status}`
  );

const getAllPortals = ({ adminId }) =>
  getRequest(`${REACT_APP_API_URL}/api/admin/tenant/list?adminId=${adminId}`);

const getTenantList = () => getRequest(`${REACT_APP_API_URL}/api/tenant/list`);

const updateSuperAdminCMS = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/cms`, data);

const isDomainExist = ({ domain, tenantId }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/admin/check-domain?domain=${domain}&tenantId=${tenantId}`
  );

const tenantViewToggleStatus = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/tenant/admin/status`, data);

const superAdminViewToggleStatus = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/admin/status`, data);

const deleteTenantAdminCategory = (data) =>
  deleteRequest(`${REACT_APP_API_URL}/api/casino/category`, data);

const deleteTenantAdminCasinoGames = (data) =>
  deleteRequest(`${REACT_APP_API_URL}/api/casino/games`, data);

const deleteTenantAdminCasinoProvider = (data) =>
  deleteRequest(`${REACT_APP_API_URL}/api/casino/provider`, data);

const deleteTenantAdminSubCategory = (data) =>
  deleteRequest(`${REACT_APP_API_URL}/api/casino/sub-category`, data);

const getAllWithdrawRequestAdmin = ({
  name,
  status,
  page,
  limit,
  startDate,
  endDate,
  paymentProvider,
}) =>
  getRequest(
    `${REACT_APP_API_URL}/api/tenant/user/all-withdraw-request?search=${name}&status=${status}&page=${page}&limit=${limit}&startDate=${startDate}&endDate=${endDate}&paymentProvider=${paymentProvider}`
  );

const approveWithdrawRequestAdmin = ({ userId, withdrawRequestId }) =>
  postRequest(`${REACT_APP_API_URL}/api/user/approve-withdraw-request`, {
    userId,
    withdrawRequestId,
  });

const cancelWithdrawRequestAdmin = (payload) =>
  postRequest(`${REACT_APP_API_URL}/api/user/cancel-withdraw-request`, payload);

const getAllWithdrawRequestSuperAdmin = ({
  name,
  status,
  page,
  limit,
  startDate,
  endDate,
  tenantId,
  adminId,
  paymentProvider,
}) =>
  getRequest(
    `${REACT_APP_API_URL}/api/user/all-withdraw-request?search=${name}&status=${status}&page=${page}&limit=${limit}&startDate=${startDate}&endDate=${endDate}&adminId=${adminId}&tenantId=${tenantId}&paymentProvider=${paymentProvider}`
  );
const getAllSAProviders = () =>
  getRequest(`${REACT_APP_API_URL}/api/casino/provider`);

const getAllTAProviders = () =>
  getRequest(`${REACT_APP_API_URL}/api/casino/provider`);

const getGlobalRegistration = () =>
  getRequest(`${REACT_APP_API_URL}/api/admin/global-registration`);

const updateGlobalRegistration = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/admin/global-registration`, data);

const getSAConvertAmount = ({ currencyFields, currencyCode, tenantIds }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/bonus/convert-amount?currencyFields=${safeStringify(
      currencyFields
    )}&currentCurrencyCode=${currencyCode}&tenantIds=${tenantIds}`
  );

const getAllBonus = ({
  adminId,
  tenantId,
  limit,
  pageNo,
  bonusType,
  isActive,
  search,
  userId,
  reorder,
}) =>
  getRequest(
    `${REACT_APP_API_URL}/api/bonus?adminId=${adminId}&tenantId=${tenantId}&limit=${limit}&pageNo=${pageNo}&search=${search}&isActive=${isActive}&bonusType=${bonusType}&userId=${userId}&reorder=${reorder}`
  );

const createBonus = (data, isTenant) =>
  postRequest(`${REACT_APP_API_URL}/api/bonus`, data);

const updateBonus = (data, isTenant) =>
  putRequest(`${REACT_APP_API_URL}/api/bonus`, data);

const getBonus = ({ bonusId, isTenant, userBonusId }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/bonus/detail?bonusId=${bonusId}&userBonusId=${userBonusId}`
  );

const issueBonus = ({ data, isTenant }) =>
  postRequest(`${REACT_APP_API_URL}/api/bonus/issue`, data);

const getUserBonus = ({ limit, pageNo, bonusType, status, userId, isTenant }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/bonus/user?limit=${limit}&pageNo=${pageNo}&bonusType=${bonusType}&status=${status}&userId=${userId}`
  );

const cancelBonus = ({ data, isTenant }) =>
  putRequest(`${REACT_APP_API_URL}/api/bonus/cancel`, data);

const getUserDocument = (userId) =>
  getRequest(`${REACT_APP_API_URL}/api/user/document?userId=${userId}`);

const verifyUserDocument = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/user/verify-document`, data);

const getDocumentLabel = (userId) =>
  getRequest(`${REACT_APP_API_URL}/api/admin/document-label?userId=${userId}`);

const updateUserDocuments = (/**@type { labeId: number } */ data) =>
  putRequest(`${REACT_APP_API_URL}/api/admin/update-user-document`, data);

const getDemographicDetailsSuperAdmin = ({ endDate, startDate, dateOptions }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/report/demographic?startDate=${startDate}&endDate=${endDate}&dateOptions=${dateOptions}`
  );

const updateDocumentLabel = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/admin/document-label`, data);

const createDocumentLabel = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/admin/document-label`, data);

const requestDocument = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/user/request-document`, data);

const cancelDocumentRequest = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/user/cancel-document-request`, data);

const getTenantPlayerManagement = ({
  endDate,
  startDate,
  limit,
  dateOptions,
}) =>
  getRequest(
    `${REACT_APP_API_URL}/api/tenant/report/top-players?startDate=${startDate}&endDate=${endDate}&limit=${limit}&dateOptions=${dateOptions}`
  );

const getSuperAdminPlayerManagement = ({
  endDate,
  startDate,
  limit,
  dateOptions,
}) =>
  getRequest(
    `${REACT_APP_API_URL}/api/report/top-players?startDate=${startDate}&endDate=${endDate}&limit=${limit}&dateOptions=${dateOptions}`
  );

const getPaymentMethod = () =>
  getRequest(`${REACT_APP_API_URL}/api/admin/bonus/payment-methods`);

const getWageringTemplateDetail = ({ wageringTemplateId }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/wagering-template/details?wageringTemplateId=${wageringTemplateId}`
  );

const getSuperAdminLivePlayerReport = (payload) =>
  getRequest(`${REACT_APP_API_URL}/api/report/live-player?${new URLSearchParams(payload)}`);

const getSuperAdminAllWageringTemplate = ({ adminId }) =>
  getRequest(`${REACT_APP_API_URL}/api/wagering-template/all`);

const getSuperAdminWageringTemplateDetail = ({
  wageringTemplateId,
  limit,
  pageNo,
  providerId,
  search,
}) =>
  getRequest(
    `${REACT_APP_API_URL}/api/wagering-template/details?wageringTemplateId=${wageringTemplateId}&pageNo=${pageNo}&limit=${limit}&providerId=${providerId}&search=${search}`
  );

const createWageringTemplate = (isTenant, data) =>
  postRequest(`${REACT_APP_API_URL}/api/wagering-template`, data);

const updateWageringTemplate = (isTenant, data) =>
  putRequest(`${REACT_APP_API_URL}/api/wagering-template`, data);
const getBalanceBonuses = ({ tenantId }) =>
  getRequest(`${REACT_APP_API_URL}/api/bonus/balance`);

const getSuperAdminWageringTemplate = ({ search, limit, pageNo }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/wagering-template?adminId=pageNo=${pageNo}&limit=${limit}&search=${search}`
  );

const getTenantWageringTemplate = ({ search, limit, pageNo }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/tenant/wagering-template?pageNo=${pageNo}&limit=${limit}&search=${search}`
  );

const getSAPlayerLiability = ({ startDate, endDate, dateOptions }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/report/players-liability?startDate=${startDate}&endDate=${endDate}&dateOptions=${dateOptions}`
  );

const getSAKPISummary = ({ startDate, endDate }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/report/kpi-summary?startDate=${startDate}&endDate=${endDate}`
  );

const getSAKPIReport = ({ startDate, endDate, dateOptions, selectedTab }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/report/kpi?tab=${selectedTab}&customStartDate=${startDate}&customEndDate=${endDate}&dateOptions=${dateOptions}`
  );

const getSAGameReport = ({
  startDate,
  endDate,
  dateOptions,
  selectedTab,
  pageNo,
  limit = 5,
}) =>
  getRequest(
    `${REACT_APP_API_URL}/api/report/game-report?startDate=${startDate}&endDate=${endDate}&dateOptions=${dateOptions}&tab=${selectedTab}&pageNo=${pageNo}&limit=${limit}`
  );

export const getSAProviderReport = ({
  startDate,
  endDate,
  dateOptions,
  selectedTab,
  pageNo,
  limit = 5,
}) =>
  getRequest(
    `${REACT_APP_API_URL}/api/report/provider-report?startDate=${startDate}&endDate=${endDate}&dateOptions=${dateOptions}&tab=${selectedTab}&pageNo=${pageNo}&limit=${limit}`
  );

export const getSAUserReport = ({
  startDate,
  endDate,
  dateOptions,
  selectedTab,
  pageNo,
  limit = 5,
}) =>
  getRequest(
    `${REACT_APP_API_URL}/api/report/user-report?startDate=${startDate}&endDate=${endDate}&dateOptions=${dateOptions}&tab=${selectedTab}&pageNo=${pageNo}&limit=${limit}`
  );

const getSAPlayerGameReport = ({
  userId,
  limit,
  startDate,
  endDate,
  dateOptions,
  selectedTab,
}) =>
  getRequest(
    `${REACT_APP_API_URL}/api/report/game?userId=${userId}&limit=${limit}&customStartDate=${startDate}&customEndDate=${endDate}&dateOptions=${dateOptions}&tab=${selectedTab}`
  );

const getFreeSpinGames = ({
  providerId,
  bonusId,
  limit,
  pageNo,
  search,
  isTenant,
}) =>
  getRequest(
    `${REACT_APP_API_URL}/api/casino/freespin-games?providerId=${providerId}&bonusId=${bonusId}&limit=${limit}&pageNo=${pageNo}&search=${search}`
  );

const getEmailTemplates = ({ isTenant }) =>
  getRequest(`${REACT_APP_API_URL}/api/email/all`);

const getEmailTemplate = ({ emailTemplateId }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/email?emailTemplateId=${emailTemplateId}`
  );

const updateEmailTemplate = ({ data, isTenant }) =>
  putRequest(`${REACT_APP_API_URL}/api/email`, data);

const getDynamicKeys = ({ isTenant, type }) =>
  getRequest(`${REACT_APP_API_URL}/api/email/dynamic-data?type=${type}`);

const createEmailTemplate = ({ data, isTenant }) =>
  postRequest(`${REACT_APP_API_URL}/api/email`, data);

const primaryEmailTemplate = ({ data, isTenant }) =>
  putRequest(`${REACT_APP_API_URL}/api/email/mark-primary`, data);

const deleteEmailTemplate = ({ data, isTenant }) =>
  deleteRequest(`${REACT_APP_API_URL}/api/email `, data);

const getloyaltyLevel = () =>
  getRequest(`${REACT_APP_API_URL}/api/bonus/loyalty-level`);

const updateloyaltyLevel = ({ isTenant, data }) =>
  putRequest(`${REACT_APP_API_URL}/api/bonus/loyalty-level`, data);

const updateLoyaltyPoint = ({ loyaltyPoint }) =>
  putRequest(`${REACT_APP_API_URL}/api/tenant/loyalty-point`, loyaltyPoint);

const updateLoyaltyLimit = ({ loyaltyLimit }) =>
  putRequest(`${REACT_APP_API_URL}/api/tenant/limit`, loyaltyLimit);

const getLanguages = ({ limit, pageNo, name }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/language?limit=${limit}&pageNo=${pageNo}&name=${name}`
  );

const updateProfile = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/admin/profile`, data);

const updateSiteConfiguration = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/admin/site-information`, data);

const getSiteConfiguration = (data) =>
  getRequest(`${REACT_APP_API_URL}/api/admin/site-information`, data);

const getAdminChildren = ({ superAdminId, superRoleId }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/admin/childs?adminId=${superAdminId}&adminRoleId=${superRoleId}`
  );

const getAllGroups = (isTenant) =>
  getRequest(`${REACT_APP_API_URL}/api/admin/all-group`);

const getTags = () => getRequest(`${REACT_APP_API_URL}/api/tenant/user/tags`);

const updateTags = ({ data, isTenant }) =>
  putRequest(`${REACT_APP_API_URL}/api/user/tags`, data);

const getImageGallery = (isTenant) =>
  getRequest(`${REACT_APP_API_URL}/api/gallery`);

const deleteFromGallery = ({ isTenant, data }) =>
  deleteRequest(`${REACT_APP_API_URL}/api/gallery`, data);

const getDuplicateUsers = ({ limit, pageNo, userId, isTenant }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/user/duplicate?limit=${limit}&pageNo=${pageNo}&userId=${userId}`
  );

const testEmailTemplateEndPoint = ({ data, isTenant }) =>
  postRequest(`${REACT_APP_API_URL}/api/email/test`, data);

const setDailyLimit = ({ isTenant, data }) =>
  postRequest(`${REACT_APP_API_URL}/api/user/daily-limit`, data);

const setDepositLimit = ({ isTenant, data }) =>
  postRequest(`${REACT_APP_API_URL}/api/user/deposit-limit`, data);

const setLossLimit = ({ isTenant, data }) =>
  postRequest(`${REACT_APP_API_URL}/api/user/loss-limit`, data);

const getOwnerPermissions = () =>
  getRequest(`${REACT_APP_API_URL}/api/admin/tenant/owner-permission`);

const setEmailCreds = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/admin/email/credentials`, data);

const disableUser = ({ isTenant, data }) =>
  postRequest(`${REACT_APP_API_URL}/api/user/disable-until`, data);

const setSessionLimit = ({ isTenant, data }) =>
  postRequest(`${REACT_APP_API_URL}/api/user/session-time`, data);

const getCMSDynamicKeys = ({ isTenant }) =>
  getRequest(`${REACT_APP_API_URL}/api/cms/dynamic-data`);

const getAllSABanners = ({ limit, pageNo }) =>
  getRequest(`${REACT_APP_API_URL}/api/banner?limit=${limit}&pageNo=${pageNo}`);

const deleteSABanners = (payload) =>
  deleteRequest(
    `${REACT_APP_API_URL}/api/banner?${new URLSearchParams(payload)}`
  );

const uploadSABanner = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/banner`, data);

const updateSABanner = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/banner`, data);

const deleteEmailTemplateLanguage = ({ isTenant, data }) =>
  deleteRequest(`${REACT_APP_API_URL}/api/email/language`, data);

const getEmailTypes = ({ isTenant }) =>
  getRequest(`${REACT_APP_API_URL}/api/email/dynamic-data`);

const deleteCMSLanguage = ({ isTenant, data }) =>
  deleteRequest(`${REACT_APP_API_URL}/api/cms`, data);

const updateReorderGames = ({ data }) =>
  putRequest(`${REACT_APP_API_URL}/api/casino/order-casino-games`, data);

const addInternalTag = ({ data, isTenant }) =>
  putRequest(`${REACT_APP_API_URL}/api/user/internal`, data);

const getLanguageSupportKeys = ({ tenantId, language, isTenant }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/language/support-keys?language=${language}`
  );

const uploadLanguageCSV = ({ data, isTenant }) =>
  postRequest(`${REACT_APP_API_URL}/api/language/load-file`, data);

const updateLanguageSupport = ({ data, isTenant }) =>
  putRequest(`${REACT_APP_API_URL}/api/language/support-keys`, data);

const getCMSLanguage = () =>
  getRequest(`${REACT_APP_API_URL}/api/cms/language`);

const getElasticHealth = (isTenant) =>
  getRequest(`${REACT_APP_API_URL}/api/report/elastic-health-check`);

const sendMail = ({ email, isSuperAdmin }) =>
  putRequest(`${REACT_APP_API_URL}/api/admin/forget-password`, { email });

const resetPassword = ({ isSuperAdmin, data }) =>
  putRequest(`${REACT_APP_API_URL}/api/admin/verify-forget-password`, data);

const updateUserAffiliate = ({ isSuperAdmin, data }) =>
  putRequest(`${REACT_APP_API_URL}/api/admin/affiliate/user`, data);

const addUserAffiliate = ({ isSuperAdmin, data }) =>
  putRequest(`${REACT_APP_API_URL}/api/admin/affiliate/add-user`, data);

const registerAffiliate = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/streamer/register`, data);

const approveAffiliate = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/streamer/approve`, data);

const rejectAffiliate = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/streamer/reject`, data);


const updateAffiliate = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/streamer/update`, data);

const getAffiliateTransactions = (data) =>
  getRequest(`${REACT_APP_API_URL}/api/streamer/affiliate-transactions?${new URLSearchParams(data)}`);

const getAffiliateWithdrawRequests = (data) =>
  getRequest(`${REACT_APP_API_URL}/api/streamer/affiliate-withdraw-requests?${new URLSearchParams(data)}`);

const approveAffiliateWithdrawRequest = (/** @type {{ affiliateId: String, affiliateWithdrawRequestId: number }} */data) =>
  postRequest(`${REACT_APP_API_URL}/api/streamer/approve-affiliate-withdraw-request`, data);

const cancelAffiliateWithdrawRequest = (/** @type {{ affiliateId: String, affiliateWithdrawRequestId: number, reason: string }} */data) =>
  postRequest(`${REACT_APP_API_URL}/api/streamer/cancel-affiliate-withdraw-request`, data);

const registerSubAffiliate = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/streamer/register-sub-affiliate`, data);

const approveSubAffiliate = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/streamer/approve-sub-affiliate`, data);

const getAffiliateUsers = (data) =>
  getRequest(`${REACT_APP_API_URL}/api/report/affiliate-users?${new URLSearchParams(data)}`);

const getAffiliateUsersPerformance = (data) =>
  getRequest(`${REACT_APP_API_URL}/api/report/affiliate-users-performance?${new URLSearchParams(data)}`);

const getSubAffiliateDailyPerformance = (data) =>
  getRequest(`${REACT_APP_API_URL}/api/report/sub-affiliate-users?${new URLSearchParams(data)}`);

const getSubAffiliatePerformance = (data) =>
  getRequest(`${REACT_APP_API_URL}/api/report/sub-affiliate-users-performance?${new URLSearchParams(data)}`);

const getUsersAccess = (data) =>
  getRequest(`${REACT_APP_API_URL}/api/report/users-access?${new URLSearchParams(data)}`);

const getUsersAccessAlert = (data) =>
  getRequest(`${REACT_APP_API_URL}/api/report/users-access-alert?${new URLSearchParams(data)}`);

const readUserAccessAlert = (payload, query) =>
  postRequest(`${REACT_APP_API_URL}/api/report/read-user-access-alert?${new URLSearchParams(query)}`, payload);

const getAdminUserViewReport = (data) =>
  getRequest(`${REACT_APP_API_URL}/api/report/get-admin-user-view?${new URLSearchParams(data)}`);

const getUserInfoUpdateReport = (data) =>
  getRequest(`${REACT_APP_API_URL}/api/report/get-user-info-updated?${new URLSearchParams(data)}`, data.exportToExcel && 'arraybuffer');

const temporarySuspendUserAccount = (payload, query) =>
  postRequest(`${REACT_APP_API_URL}/api/user/temporary-suspend?${new URLSearchParams(query)}`, payload);

const temporarySuspendReactivate = (payload, query) =>
  postRequest(`${REACT_APP_API_URL}/api/user/temporary-suspend-reactivate?${new URLSearchParams(query)}`, payload);

const getUsersTemporaySuspension = (data) =>
  getRequest(`${REACT_APP_API_URL}/api/report/get-user-temporary-suspension?${new URLSearchParams(data)}`);

const getUserWallets = (payload) => {
  if (payload.exportToExcel) {
    return getRequest(
      `${REACT_APP_API_URL}/api/report/user-wallets?${new URLSearchParams(payload)}`,
      'arraybuffer'
    );
  }
  return getRequest(`${REACT_APP_API_URL}/api/report/user-wallets?${new URLSearchParams(payload)}`);
}

const getPaymentAggregators = (isTenant) =>
  getRequest(`${REACT_APP_API_URL}/api/admin/payment/aggregators`);

const getPaymentProvider = ({
  isTenant,
  paymentType,
  aggregator,
  tenant,
  group,
  status,
  tenantIds,
  category,
}) =>
  getRequest(
    `${REACT_APP_API_URL}/api/admin/payment/providers?paymentType=${paymentType}&aggregator=${aggregator}&tenant=${tenant}&group=${group}&status=${status}&tenantIds=${tenantIds}&category=${category}`
  );

const getProviderDetails = ({ providerId, isTenant }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/admin/payment/provider-details?providerId=${providerId}`
  );

const configProviderSettings = ({ data, isTenant }) =>
  putRequest(`${REACT_APP_API_URL}/api/admin/payment/provider-settings`, data);

const updateKycMethod = ({ data }) =>
  putRequest(`${REACT_APP_API_URL}/api/country/kyc-method`, data);

const updatePaymentProviderStatus = ({ data, isTenant }) =>
  putRequest(`${REACT_APP_API_URL}/api/admin/update-provider-status`, data);

const reOrderPaymentProviders = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/tenant/payment/order-providers`, data);

const getCommentsList = ({
  limit,
  search,
  pageNo,
  userId,
  isTenant,
  role,
  status,
}) =>
  getRequest(
    `${REACT_APP_API_URL}/api/user/comments?limit=${limit}&pageNo=${pageNo}&search=${search}&userId=${userId}&role=${role}&status=${status}`
  );

const addComments = ({ isTenant, data }) =>
  postRequest(`${REACT_APP_API_URL}/api/user/comment`, data);

const updateSAUserStatus = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/admin/status`, data);

const logoutUser = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/admin/user-logout`, data);

const updateComment = ({ data, isTenant }) =>
  putRequest(`${REACT_APP_API_URL}/api/user/comment-status`, data);
const reorderBonus = ({ data, isTenant }) =>
  putRequest(`${REACT_APP_API_URL}/api/bonus/order`, data);

const getSegments = ({ isTenant }) =>
  getRequest(`${REACT_APP_API_URL}/api/admin/bonus/segments`);

const deleteBonus = ({ isTenant, data }) =>
  deleteRequest(`${REACT_APP_API_URL}/api/bonus`, data);

const verifyPlayerEmail = ({ isTenant, data }) =>
  putRequest(`${REACT_APP_API_URL}/api/user/verify-email`, data);

const getAllReviews = ({ limit, search, pageNo, status }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/admin/review?limit=${limit}&pageNo=${pageNo}&search=${search}&status=${status}`
  );

const addReview = ({ data }) =>
  postRequest(`${REACT_APP_API_URL}/api/admin/review`, data);

const updateReview = ({ data }) =>
  putRequest(`${REACT_APP_API_URL}/api/admin/review`, data);

const resetProfilePassword = ({ isTenant, data }) =>
  putRequest(`${REACT_APP_API_URL}/api/admin/change-password`, data);

const resetPasswordEmail = ({ data }) =>
  putRequest(`${REACT_APP_API_URL}/api/user/reset-password`, data);

const resetUserPassword = ({ isTenant, data }) =>
  putRequest(`${REACT_APP_API_URL}/api/user/update-password`, data);

const getPaymentCategories = ({ paymentType }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/admin/payment/category?paymentType=${paymentType}`
  );

const orderPaymentCategories = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/admin/payment/order-category`, data);

const updateUser = ({ data, isTenant }) =>
  putRequest(`${REACT_APP_API_URL}/api/user`, data);

const getCashbackData = ({ isTenant, userId, level, currencyCode }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/user/cashback?userId=${userId}&level=${level}&currencyCode=${currencyCode}`
  );

const getBetSettings = () =>
  getRequest(`${REACT_APP_API_URL}/api/sportsbook/bet-settings`);

const updateBetSettings = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/sportsbook/bet-settings`, data);

const addBetSettings = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/sportsbook/bet-settings`, data);

const getSportsList = ({
  search = "",
  limit,
  pageNo,
  isAllListing = false,
  isActive = "",
}) => {
  return isAllListing
    ? getRequest(
      `${REACT_APP_API_URL}/api/sportsbook/sport?limit=${limit}&pageNo=${pageNo}&search=${search}&isActive=${isActive}&listing=all`
    )
    : getRequest(
      `${REACT_APP_API_URL}/api/sportsbook/sport?limit=${limit}&pageNo=${pageNo}&search=${search}&isActive=${isActive}`
    );
};

const getCountriesList = ({ limit, pageNo, search = "", isActive = "" }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/sportsbook/countries?search=${search}&limit=${limit}&pageNo=${pageNo}&isActive=${isActive}`
  );

const getMatchesList = ({
  limit,
  pageNo,
  isLive,
  isFeatured,
  providerSportId,
  eventStatus,
  search = "",
  isActive = "",
}) =>
  getRequest(
    `${REACT_APP_API_URL}/api/sportsbook/matches?search=${search}&isActive=${isActive}&limit=${limit}&pageNo=${pageNo}&isLive=${isLive}&eventStatus=${eventStatus}&isFeatured=${isFeatured}&providerSportId=${providerSportId}`
  );

const getMarketsList = ({ limit, pageNo, isLive, providerSportId }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/sportsbook/markets?limit=${limit}&pageNo=${pageNo}&isLive=${isLive}&providerSportId=${providerSportId}`
  );

const getTournamentsList = ({
  limit,
  pageNo,
  providerCountryId,
  providerSportId,
  search,
  isActive,
}) =>
  getRequest(
    `${REACT_APP_API_URL}/api/sportsbook/tournaments?limit=${limit}&pageNo=${pageNo}&providerCountryId=${providerCountryId}&providerSportId=${providerSportId}&search=${search}&isActive=${isActive}`
  );

const updateStatus = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/admin/status`, data);

const addFeaturedApi = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/admin/sportsbook/addFeatured`, data);

const uploadImageApi = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/sportsbook/upload-thumbnails`, data);

const getSportsMatchesDetailApi = ({ matchId = "" }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/sportsbook/match-markets?matchId=${matchId}`
  );

const getSpecificSportDetailApi = ({ matchId = "" }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/sportsbook/match-markets?matchId=${matchId}`
  );

const updateOddsVariationApi = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/sportsbook/odd-settings`, data);

const detachOddsVariationApi = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/sportsbook/detach-market`, data);

const updateCompanyOddApi = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/sportsbook/custom-odds`, data);

const affiliateLogin = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/affiliate/login`, data);

const getAffiliateAdminDetails = () =>
  getRequest(`${REACT_APP_API_URL}/api/streamer/details`);

const getAffiliateAdminUserList = ({ playerType, search }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/streamer/joined-users?playerType=${playerType}&search=${search || ""
    }`
  );

const getAffiliateReferralCode = () =>
  getRequest(`${REACT_APP_API_URL}/api/streamer/get-referral-code`);
const getAffiliateDashboardData = (payload) =>
  getRequest(`${REACT_APP_API_URL}/api/streamer/affiliate-dashboard?${new URLSearchParams(
    payload
  )}`);

export const getAffiliateProductsReport = () =>
  getRequest(`${REACT_APP_API_URL}/api/report/all-game-categories-report`);
export const getAffiliatePlayersReport = (payload) =>
  getRequest(
    `${REACT_APP_API_URL}/api/report/affiliate-player-report?${new URLSearchParams(
      payload
    )}`
  );
export const getAffiliateCommissionsReport = (payload) =>
  getRequest(
    `${REACT_APP_API_URL}/api/report/affiliate-report?${new URLSearchParams(
      payload
    )}`
  );

export const getAffiliateLiveCommissions = (/**@type {{ affiliateId?: string | number }}*/ payload) =>
  getRequest(
    `${REACT_APP_API_URL}/api/report/affiliate-live-commission?${new URLSearchParams(payload)}`
  );

export const getSubAffiliateCommissionsReport = (/**@type {{ affiliateId?: string | number }}*/ payload) =>
  getRequest(
    `${REACT_APP_API_URL}/api/report/sub-affiliate-comission-report?${new URLSearchParams(payload)}`
  );

export const getSubAffiliateLiveCommissionsReport = (/**@type {{ affiliateId?: string | number }}*/ payload) =>
  getRequest(
    `${REACT_APP_API_URL}/api/report/sub-affiliate-live-comission-report?${new URLSearchParams(payload)}`
  );

export const getAffiliateLivesReport = (/**@type {{ streamerId?: string, limit?: number, pageNo?: number }}*/ payload) => {
  if (payload.exportToExcel) {
    return getRequest(
      `${REACT_APP_API_URL}/api/report/affiliate-lives-report?${new URLSearchParams(payload)}`,
      'arraybuffer'
    );
  }
  return getRequest(
    `${REACT_APP_API_URL}/api/report/affiliate-lives-report?${new URLSearchParams(payload)}`
  );
}



export const getAffiliateTopPlayers = (payload) =>
  getRequest(
    `${REACT_APP_API_URL}/api/report/top-affiliate-players?${new URLSearchParams(
      payload
    )}`
  );

export const getAffiliateStreamLogs = (/**@type {{ streamId?: string, streamerId?: string }}*/ payload) =>
  getRequest(
    `${REACT_APP_API_URL}/api/streamer/get-stream-logs?${new URLSearchParams(payload)}`
  );

export const getAffiliateAssociatedPlayerDetails = (payload) =>
  getRequest(
    `${REACT_APP_API_URL}/api/streamer/player-details?${new URLSearchParams(
      payload
    )}`
  );
export const getAffiliateAssociatedPlayerCasinoTransaction = (payload) =>
  getRequest(
    `${REACT_APP_API_URL}/api/streamer/player-casino-transactions?${new URLSearchParams(
      payload
    )}`
  );
export const getAffiliateAssociatedPlayerTransactionBanking = (payload) =>
  getRequest(
    `${REACT_APP_API_URL}/api/streamer/player-banking-transactions?${new URLSearchParams(
      payload
    )}`
  );
export const getAfffiliateTransactionBanking = (payload) =>
  getRequest(
    `${REACT_APP_API_URL}/api/streamer/transaction-banking?${new URLSearchParams(
      payload
    )}`
  );

/** Social Links Api  */
export const getSocialLinks = () =>
  getRequest(`${REACT_APP_API_URL}/api/social-links`);
export const updateSocialLink = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/social-links`, data);
export const createSocialLink = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/social-links`, data);
export const deleteSocialLink = (payload) =>
  deleteRequest(
    `${REACT_APP_API_URL}/api/social-links?${new URLSearchParams(payload)}`
  );
/** */

/** Loyalty Rules Api  */
export const getLoyaltyRules = () =>
  getRequest(`${REACT_APP_API_URL}/api/loyalty/rules`);
export const updateLoyaltyRule = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/loyalty/rule`, data);
export const createLoyaltyRule = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/loyalty/add-rule`, data);
export const deleteLoyaltyRule = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/loyalty/pop-rule`, data);
export const setMinimumBetAmount = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/loyalty/minimum-bet-amount`, data);
export const getMinimumBetAmount = () =>
  getRequest(`${REACT_APP_API_URL}/api/loyalty/minimum-bet-amount`);
/** */

export const addSomeLoyaltyPoinstToUser = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/user/give-loyalty-points`, data);

/**
 * Referral Management API
 */
export const getReferralManagement = (data) =>
  getRequest(
    `${REACT_APP_API_URL}/api/referral-management?${new URLSearchParams(data)}`
  );
export const updateReferralManagement = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/referral-management`, data);

export const generateAdminOtp = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/admin/generate-otp`, data);
export const updateAffiliatePassword = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/user/update-affiliate-password`, data);
export const getAffiliateDashboardDataById = (data) =>
  getRequest(
    `${REACT_APP_API_URL}/api/streamer/dashboard-data?${new URLSearchParams(
      data
    )}`
  );



export const getAffiliateCommissionReportById = (data) =>
  getRequest(
    `${REACT_APP_API_URL}/api/streamer/commission-report?${new URLSearchParams(
      data
    )}`
  );


export const getChartAnalysis = (data) =>
  getRequest(
    `${REACT_APP_API_URL}/api/report/graph-analysis?${new URLSearchParams(
      data
    )}`
  );


export const updateAffiliateStatus = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/admin/status`, data);

const createUserIdentifierChecks = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/user-identifier-checks`, data);

const queryDataNewLife = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/newlife`, data);


// PAYMENTS
/** Payment provider list
 * @typedef { {providers: any[], pix: any[], payment_types: string[]} } PaymentProvidersResponse
 * @type { (payload?: { withdrawal?: boolean, deposit?: boolean }) => Promise<PaymentProvidersResponse> }
 */
const getPaymentProviders = (payload) =>
  getRequest(`${REACT_APP_API_URL}/api/payments/providers?${new URLSearchParams(payload)}`);

/** Withdraw with pix
 * @typedef { {} } WithdrawPixResponse
 * @typedef { {value: number, paymentProviderId: number, pix_key_type: string, pix_key: string} } WithdrawPixPayload
 * @type { (payload: WithdrawPixPayload) => Promise<WithdrawPixResponse> }
 */
const withdrawPix = (payload) =>
  postRequest(`${REACT_APP_API_URL}/api/payments/withdraw/pix`, payload);


const getPaymentTransactionSettingsService = () =>
  getRequest(`${REACT_APP_API_URL}/api/admin/payment-transactions-settings`);

const updatePaymentTransactionSettingsService = (/** @type {{key?: String, value: object}}*/payload) =>
  putRequest(`${REACT_APP_API_URL}/api/admin/payment-transactions-settings`, payload);

const getGlobalSettingsService = (/** @type {{ keys: (keyof typeof GLOBAL_SETTINGS_KEY)[] }} */{ keys = [], ...payload }) => {
  const searchParams = new URLSearchParams(payload);
  keys.forEach((value) => searchParams.append("keys", value));
  return getRequest(`${REACT_APP_API_URL}/api/admin/global-settings?${searchParams.toString()}`);
}

const updateGlobalSettingsService = (/** @type {{ [Key in keyof typeof GLOBAL_SETTINGS_KEY]: object}}*/payload) =>
  putRequest(`${REACT_APP_API_URL}/api/admin/global-settings`, payload);

const getAdminInformationSettingsService = () => {
  return getRequest(`${REACT_APP_API_URL}/api/admin/settings`);
}

const subAffiliateCountLinkVisit = (/** @type {{ referralCode: string, storage: string }}*/ data) => {
  return postRequest(`${REACT_APP_API_URL}/api/streamer/visit`, data);
}

const getReasonsForSuspension = (payload) =>
  getRequest(`${REACT_APP_API_URL}/api/cms/reasons-suspension?${new URLSearchParams(payload).toString()}`,);

export const sigapService = {
  getSigapLotesReport: (query) => getRequest(`${REACT_APP_API_URL}/api/sigap/get-sigap-lotes-report?${new URLSearchParams(query)}`),
  getSigapOperators: (query) => getRequest(`${REACT_APP_API_URL}/api/sigap/get-sigap-operators?${new URLSearchParams(query)}`),

  sendSigapUserWallets: (payload, query) => postRequest(`${REACT_APP_API_URL}/api/sigap/sigap_wallet?${new URLSearchParams(query)}`, serialize(payload)),
  sendSigapDailyGames: (payload, query) => postRequest(`${REACT_APP_API_URL}/api/sigap/sigap_game?${new URLSearchParams(query)}`, serialize(payload)),
  sendSigapBettors: (payload, query) => postRequest(`${REACT_APP_API_URL}/api/sigap/sigap_bets?${new URLSearchParams(query)}`, serialize(payload)),
  sendSigapDailyOperators: (payload, query) => postRequest(`${REACT_APP_API_URL}/api/sigap/sigap_operator_day?${new URLSearchParams(query)}`, serialize(payload)),
  sendSigapMonthlyOperators: (payload, query) => postRequest(`${REACT_APP_API_URL}/api/sigap/sigap_operator_monthy?${new URLSearchParams(query)}`, serialize(payload)),
  sendSigapSportsBetting: (payload, query) => postRequest(`${REACT_APP_API_URL}/api/sigap/sigap_sports?${new URLSearchParams(query)}`, serialize(payload)),

  exportSigapUserWallets: (query) => getRequest(`${REACT_APP_API_URL}/api/sigap/sigap_wallet?${new URLSearchParams(query)}`),
  exportSigapDailyGames: (query) => getRequest(`${REACT_APP_API_URL}/api/sigap/sigap_game?${new URLSearchParams(query)}`),
  exportSigapBettors: (query) => getRequest(`${REACT_APP_API_URL}/api/sigap/sigap_bets?${new URLSearchParams(query)}`),
  exportSigapDailyOperators: (query) => getRequest(`${REACT_APP_API_URL}/api/sigap/sigap_operator_day?${new URLSearchParams(query)}`),
  exportSigapMonthlyOperators: (query) => getRequest(`${REACT_APP_API_URL}/api/sigap/sigap_operator_monthy?${new URLSearchParams(query)}`),
  exportSigapSportsBetting: (query) => getRequest(`${REACT_APP_API_URL}/api/sigap/sigap_sports?${new URLSearchParams(query)}`),

  registerOperatorDailyValues: (payload, query) => postRequest(`${REACT_APP_API_URL}/api/sigap/sigap_operator_day/create?${new URLSearchParams(query)}`, payload),
  registerOperatorMonthyValues: (payload, query) => postRequest(`${REACT_APP_API_URL}/api/sigap/sigap_operator_monthy/create?${new URLSearchParams(query)}`, payload),
  updateOperatorDailyValues: (payload, query) => putRequest(`${REACT_APP_API_URL}/api/sigap/sigap_operator_day/update?${new URLSearchParams(query)}`, payload),
  updateOperatorMonthyValues: (payload, query) => putRequest(`${REACT_APP_API_URL}/api/sigap/sigap_operator_monthy/update?${new URLSearchParams(query)}`, payload),
  getOperatorDailyRecords: (query) => getRequest(`${REACT_APP_API_URL}/api/sigap/sigap_operator_day/get-all?${new URLSearchParams(query)}`),
  getOperatorMonthyRecords: (query) => getRequest(`${REACT_APP_API_URL}/api/sigap/sigap_operator_monthy/get-all?${new URLSearchParams(query)}`),

  getMovimentoSearch: (query) => getRequest(`${REACT_APP_API_URL}/api/sigap/sigap-movimento-search?${new URLSearchParams(query)}`),
}

export {
  addReview,
  getAllReviews,
  updateReview,
  superAdminLogin,
  // tenantLogin,
  // getAllTenants,
  getAllAdmins,
  // createTenant,
  // getThemeData,
  // getAllThemes,
  getAllCurrencies,
  // getAllTenantAffiliates,
  // getAllTenantAdmins,
  // getAllTenantCMS,
  createCurrency,
  editCurrency,
  getAllAffiliates,
  getAllCms,
  getAllUsers,
  // createTenantAdmin,
  // getTenant,
  // editTenant,
  getAdminRole,
  getAdmin,
  getCurrencyById,
  getUser,
  // getAllCredKeys,
  // updateTheme,
  // createTheme,
  // getAllCredentials,
  // createCredentials,
  // getAllCreds,
  // getCredByKey,
  // updateCreds,
  // getTenantDetails,
  // getAllTenantUsers,
  // createTenantCredentials,
  // getTenantUserDetails,
  // getUserFields,
  // updateUserFields,
  // updateTenantCredentials,
  // getAdminRoles,
  getAdminUsers,
  getAdminUserDetails,
  // createAdminUser,
  // createTenantUser,
  // updateTenantUser,
  // getAllThemesTenant,
  // updateThemeTenant,
  // getTenantAffiliateById,
  // updateTenantAffiliate,
  createCms,
  // getCmsDetails,
  // updateCms,
  // getTenantRole,
  // getTenantAdminRole,
  // getTenantWallet,
  getSuperAdminWallet,
  getAffiliateById,
  getCmsByPageId,
  // getTenantCasinoCategories,
  // getTenantCasinoSubCategories,
  createTenantCasinoCategory,
  getTenantCasinoMenuById,
  updateTenantCasinoCategory,
  getAllCasinoProviders,
  getAllCasinoGames,
  getCountries,
  createSuperAdminUser,
  updateSuperAdminUser,
  getAllTenantCasinoMenuItems,
  getSuperAdminDetails,
  createCasinoGame,
  createCasinoProvider,
  updateCasinoGame,
  updateCasinoProvider,
  createTenantCasinoMenuItem,
  getAllWageringCasinoGames,
  // getAllTenantCasinoTables,
  updateTenantCasinoMenuItem,
  // updateTenantAdminUser,
  updateTenantAdmin,
  getAllSuperAdminTransactions,
  // getAllTenantTransactions,
  // getAllTenantCasinoTransactions,
  // getTenantTransactionUsers,
  getSuperAdminTransactionUsers,
  // getTenantCountries,
  getSuperAdminAggregators,
  createSuperAdminAggregator,
  getSuperAdminGameCategory,
  getSuperAdminGameSubCategory,
  createTenantCasinoSubCategory,
  updateTenantCasinoSubCategory,
  // getTenantGames,
  getMasterGames,
  addGamesToSubCategory,
  // deleteTenantCasinoGame,
  // updateTenantCategoryGame,
  updateSubCategoryReOrder,
  updateCategoryReOrder,
  getRestrictedItems,
  getUnRestrictedItems,
  addRestrictedItems,
  deleteRestrictedItems,
  getRestrictedCountries,
  getUnRestrictedCountries,
  deleteRestrictedCountries,
  addRestrictedCountries,
  // getAllTenantCurrencies,
  addDepositToOther,
  manageAffiliateMoney,
  getSuperAdminCasinoTransactions,
  getAllClients,
  getAllPortals,
  getTenantList,
  isDomainExist,
  updateSuperAdminCMS,
  superAdminViewToggleStatus,
  tenantViewToggleStatus,
  deleteTenantAdminCategory,
  deleteTenantAdminSubCategory,
  getAllWithdrawRequestAdmin,
  getAllWithdrawRequestSuperAdmin,
  getAllSAProviders,
  getAllTAProviders,
  getGlobalRegistration,
  updateGlobalRegistration,
  getSAConvertAmount,
  getAllBonus,
  createBonus,
  updateBonus,
  getBonus,
  issueBonus,
  getUserBonus,
  cancelBonus,
  getUserDocument,
  verifyUserDocument,
  getDocumentLabel,
  updateDocumentLabel,
  updateUserDocuments,
  // getDemographicDetailsTenant,
  getDemographicDetailsSuperAdmin,
  createDocumentLabel,
  requestDocument,
  cancelDocumentRequest,
  getPaymentMethod,
  getWageringTemplateDetail,
  getSuperAdminPlayerManagement,
  getTenantPlayerManagement,
  getSuperAdminLivePlayerReport,
  // getTenantLivePlayerReport,
  getSuperAdminAllWageringTemplate,
  // getTenantAllWageringTemplate,
  getSuperAdminWageringTemplateDetail,
  createWageringTemplate,
  updateWageringTemplate,
  getBalanceBonuses,
  // getTAConvertAmount,
  // getAllTABonus,
  // getTAWageringTemplateDetail,
  getSuperAdminWageringTemplate,
  getTenantWageringTemplate,
  // getTenantWageringTemplateDetail,
  // getTABalanceBonuses,
  getSAPlayerLiability,
  // getTAPlayerLiability,
  getSAKPISummary,
  // getTAKPISummary,
  getSAKPIReport,
  // getTAKPIReport,
  getSAGameReport,
  // getTAGameReport,
  getSAPlayerGameReport,
  // getTAPlayerGameReport,
  getFreeSpinGames,
  // getTenantOwnerDetails,
  getloyaltyLevel,
  updateloyaltyLevel,
  getLanguages,
  getEmailTemplates,
  getEmailTemplate,
  primaryEmailTemplate,
  updateEmailTemplate,
  createEmailTemplate,
  updateLoyaltyPoint,
  updateLoyaltyLimit,
  getDynamicKeys,
  getAdminChildren,
  updateProfile,
  // updateAdminProfile,
  getAllGroups,
  getTags,
  updateTags,
  getImageGallery,
  deleteFromGallery,
  getDuplicateUsers,
  testEmailTemplateEndPoint,
  setDailyLimit,
  setDepositLimit,
  setLossLimit,
  getOwnerPermissions,
  setEmailCreds,
  disableUser,
  setSessionLimit,
  // getBanners,
  // uploadBanner,
  getCMSDynamicKeys,
  deleteEmailTemplateLanguage,
  getEmailTypes,
  getAllSABanners,
  uploadSABanner,
  updateSABanner,
  deleteCMSLanguage,
  updateReorderGames,
  // getTenantLanguages,
  addInternalTag,
  getLanguageSupportKeys,
  uploadLanguageCSV,
  updateLanguageSupport,
  getCMSLanguage,
  getElasticHealth,
  sendMail,
  resetPassword,
  updateUserAffiliate,
  addUserAffiliate,
  updateKycMethod,
  getPaymentAggregators,
  getPaymentProvider,
  getProviderDetails,
  configProviderSettings,
  updatePaymentProviderStatus,
  reOrderPaymentProviders,
  getCommentsList,
  addComments,
  updateSAUserStatus,
  logoutUser,
  updateComment,
  reorderBonus,
  getSegments,
  deleteBonus,
  verifyPlayerEmail,
  resetProfilePassword,
  resetPasswordEmail,
  resetUserPassword,
  getPaymentCategories,
  orderPaymentCategories,
  updateUser,
  getCashbackData,
  updateSiteConfiguration,
  getSiteConfiguration,
  getSuperAdminSportsTransactions,
  deleteTenantAdminCasinoProvider,
  deleteTenantAdminCasinoGames,
  updateCasinoSubCategory,
  updateCasinoCategory,
  createCasinoCategory,
  createCasinoSubCategory,
  deleteSABanners,
  deleteEmailTemplate,
  getBetSettings,
  updateBetSettings,
  addBetSettings,
  getSportsList,
  getCountriesList,
  getMatchesList,
  getMarketsList,
  getTournamentsList,
  updateStatus,
  addFeaturedApi,
  uploadImageApi,
  getSportsMatchesDetailApi,
  getSpecificSportDetailApi,
  updateOddsVariationApi,
  detachOddsVariationApi,
  updateCompanyOddApi,
  registerAffiliate,
  registerSubAffiliate,
  approveSubAffiliate,
  updateAffiliate,
  getAffiliateTransactions,
  getAffiliateWithdrawRequests,
  approveAffiliateWithdrawRequest,
  cancelAffiliateWithdrawRequest,
  getAffiliateUsers,
  getAffiliateUsersPerformance,
  getSubAffiliateDailyPerformance,
  getSubAffiliatePerformance,
  affiliateLogin,
  getAffiliateAdminDetails,
  getAffiliateAdminUserList,
  approveWithdrawRequestAdmin,
  cancelWithdrawRequestAdmin,
  getAffiliateReferralCode,
  getAffiliateDashboardData,
  createUserIdentifierChecks,
  queryDataNewLife,

  // PAYMENTS
  getPaymentProviders,
  withdrawPix,

  getPaymentTransactionSettingsService,
  updatePaymentTransactionSettingsService,

  approveAffiliate,
  rejectAffiliate,

  // GLOBAL SETTINGS
  getGlobalSettingsService,
  updateGlobalSettingsService,
  getAdminInformationSettingsService,

  subAffiliateCountLinkVisit,
  getUsersAccess,
  getUsersAccessAlert,
  readUserAccessAlert,
  getUserWallets,

  getAdminUserViewReport,
  getUserInfoUpdateReport,

  temporarySuspendUserAccount,
  temporarySuspendReactivate,
  getUsersTemporaySuspension,
  getReasonsForSuspension,
};
