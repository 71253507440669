import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Table } from "react-bootstrap";
import { Loading } from "../../../../components/Preloader";
import PaginationComponent from "../../../../components/Pagination";
import FormatDate from "../../../../utils/formatDate";
import { sigapService } from "../../../../utils/apiCalls";
import { resolveErrors } from "../../../../utils/errorTypes";
import FormatNumber from "../../../../utils/formatNumber";
import Button from "../../../../components/Button";
import { Col, Form, Modal } from "@themesberg/react-bootstrap";
import { ErrorMessage, Formik, Form as FormikForm } from "formik";
import CurrencyInput from "../../../../components/CurrencyInput";
import * as Yup from 'yup';
import { toast } from "../../../../components/Toast";

export const tableHeaders = [
  { label: 'ID' },
  { label: 'Data do movimento de referência', className: "text-left" },
  { label: 'Marca', className: "text-left" },
  { label: 'Total GGR', className: "text-left" },
  { label: 'Média de Tempo de Acesso Diário' },
  { label: 'Criado em:' },
  { label: 'Ação' },
];

const SigapOperatorMonthlyReportEditButton = ({ result, reloadList = () => null }) => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = async (formValues) => {
    console.log({ formValues });
    setLoading(true);
    try {
      await sigapService.updateOperatorMonthyValues(formValues);
      reloadList();
      toast("Salvo com sucesso!", "success");
    } catch (err) {
      toast(resolveErrors(err?.response?.data?.errors, `Error ao salvar registro de operação diário`), "error");
    } finally {
      setLoading(false);
    }
  };

  return (<>
    <Button onClick={() => setShow(true)}>Editar</Button>
    <Modal show={show}>
      <Modal.Header>Editar operação mensal da data de referência "{FormatDate.toDateOnly(result.dataMovimentoRef)}" da marca "{result.SigapOperator.name}"?</Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            sigapOperatorMonthlyId: result.sigapOperatorMonthlyId,
            ggrTotal: result.ggrTotal,
            mediaTempoAcessoDiario: result.mediaTempoAcessoDiario,
          }}
          validationSchema={
            Yup.object().shape({
              ggrTotal: Yup.number().min(0, ""),
              mediaTempoAcessoDiario: Yup.number().min(0, ""),
            })
          }
          onSubmit={onSubmit}
        >
          {({ values, setFieldValue, handleSubmit }) => {
            return (
              <FormikForm>
                <Col xs={12} className="mb-3">
                  <Form.Label>Valor GGR Total Operador</Form.Label>
                  <CurrencyInput
                    name={"ggrTotal"}
                    defaultValue={FormatNumber.currency(values.ggrTotal)}
                    onNumberFormat={(event) => {
                      setFieldValue('ggrTotal', event.detail.number)
                    }}
                  />
                  <ErrorMessage
                    component="small"
                    name={"saldoTotalContTrans"}
                    className="text-danger"
                  />
                </Col>
                <Col xs={12} className="mb-3">
                  <Form.Label>Média de Tempo de Acesso Diário</Form.Label>
                  <Form.Control
                    type="number"
                    min={0}
                    step="2"
                    name={"mediaTempoAcessoDiario"}
                    value={values.mediaTempoAcessoDiario}
                    onChange={({ target: { value } }) => {
                      setFieldValue('mediaTempoAcessoDiario', Number(value))
                    }}
                  />
                  <ErrorMessage
                    component="small"
                    name={"mediaTempoAcessoDiario"}
                    className="text-danger"
                  />
                </Col>
                <Modal.Footer>
                  <Button onClick={() => setShow(false)} variant='outline-warning'>Fechar</Button>
                  <Button onClick={handleSubmit} loading={loading} variant='success'>Salvar</Button>
                </Modal.Footer>
              </FormikForm>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  </>)
};

export const SigapOperatorMonthlyReportTable = forwardRef(({ }, ref) => {
  const [data, setData] = useState({});
  const [initialLoading, setInitialLoading] = useState(true);
  const [page, setPage] = useState(1);

  const getOperatorMonthyRecords = async ({ initialLoading, loading, page = 1 } = {}) => {
    if (initialLoading) setInitialLoading(true);
    try {
      const res = await sigapService.getOperatorMonthyRecords({ limit: 7, pageNo: page });
      setData(res.data.data);
    } catch (err) {
      toast(resolveErrors(err?.response?.data?.errors, `Error ao obter dados do relatório`), "error");
    } finally {
      setInitialLoading(false)
    }
  }

  useImperativeHandle(ref, () => ({ reload: getOperatorMonthyRecords }));

  useEffect(() => {
    getOperatorMonthyRecords({ initialLoading: true });
  }, []);

  return (
    <div className="TableBg mt-4 pt-1">
      <div className="section-header-wrap m-0 mt-2 py-3 px-3">
        <h2 className="title-text">Registros de operações mensais</h2>
      </div>
      <div className='position-relative h-100'>
        <Table striped responsive hover size="sm" className="text-center mt-3">
          <thead className="thead-light">
            <tr>
              {tableHeaders.map((h, idx) => (
                <th key={idx} className={h.className}>{h.label}</th>
              ))}
            </tr>
          </thead>

          <tbody >
            {(!initialLoading) &&
              data?.result?.map(
                (result) => {
                  return (
                    <tr key={result.sigapOperatorMonthlyId}>
                      <td>{result.sigapOperatorMonthlyId}</td>
                      <td>{FormatDate.toDateOnly(result.dataMovimentoRef)}</td>
                      <td className="text-left">{result.SigapOperator?.name}</td>
                      <td className="text-left">{FormatNumber.currency(result.ggrTotal ?? 0)}</td>
                      <td className="text-center">{FormatDate.unixToDuration(result.mediaTempoAcessoDiario ?? 0)}</td>
                      <td>{FormatDate.toDateHour(result.createdAt)}</td>
                      <td><SigapOperatorMonthlyReportEditButton {...{ result, reloadList: getOperatorMonthyRecords }} /></td>
                    </tr>
                  );
                }
              )}

            {data?.result?.length === 0 && (
              <tr>
                <td colSpan={13} className="text-danger text-center">
                  Sem dados
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        {(initialLoading) ? <Loading className={"position-absolute bg-white top-0 w-100 h-100"} /> : null}
      </div>
      {<div className='row px-3 mt-3'>
        <div className='col-sm-6 col-12 mb-3 mb-sm-0 justify-content-end justify-content-sm-start  d-flex align-items-center'>
          Total de registros ({data?.total || 0})
        </div>
        <div className='col-sm-6 col-12 d-flex justify-content-end'>
          {data?.total !== 0 && !initialLoading && (
            <PaginationComponent
              className=""
              page={data?.total < page ? setPage(1) : page}
              totalPages={data?.totalPages}
              setPage={(page) => {
                setPage(page);
                getOperatorMonthyRecords({ initialLoading: true, page });
              }}
            />
          )}
        </div>
      </div>}
    </div>
  )
});

export default SigapOperatorMonthlyReportTable;