import * as Yup from 'yup';

export const createCasinoGameFormInitialValues = {
  isActive: true,
  name: '',
  identifier: '',
  gameAggregatorId: null,
  gameSubCategoryId: null,
  gameCategoryId: 2,
  casinoProviderId: null,
  thumbnail: null,
  devices: {
    isMobile: false,
    isDesktop: false
  }
};

export const createCasinoGameFormSchema = Yup.object().shape({
  name: Yup
    .string()
    .required('Informe o nome do jogo'),
  identifier: Yup
    .string()
    .required('Identificador é obrigatório'),
  gameAggregatorId: Yup
    .number('Selecione o agregador de jogos')
    .required('Selecione o agregador de jogos')
    .nullable(),
  gameSubCategoryId: Yup
    .number('Selecione a sub-categoria')
    .required('Selecione a sub-categoria')
    .nullable(),
  casinoProviderId: Yup
    .number('Selecione o provedor')
    .required('Selecione o provedor')
    .nullable(),
  thumbnail: Yup
    .mixed()
    .required('Thumbnail é obrigatório')
    .test('File Size',
      'Envie uma thumbnail de até 1MB', (value) => !value || (value && value.size <= 1024 * 1024))
    .test('FILE_FORMAT', 'Uploaded file has unsupported format.',
      (value) => !value || (value && ['image/png', 'image/jpeg', 'image/jpg'].includes(value.type))).nullable(),
  devices: Yup.object().shape({
    isDesktop: Yup.boolean()
      .when('isMobile', {
        is: false,
        then: Yup.boolean().oneOf([true], 'Selecione pelo menos uma versão (Desktop ou Mobile) ou os dois'),
      }),
  }),
})
