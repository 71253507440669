import React, { useEffect, useState } from "react";
import { BsBellFill } from "react-icons/bs";
import "./style.scss";
import { getUsersAccessAlert } from "../../../../../utils/apiCalls";
import { formatDateYMD } from "../../../../../utils/dateFormatter";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { AdminsRoutes } from "../../../../../routes";

export const UsersAccessAlertNotify = () => {
  const [data, setData] = useState([]);

  const getUserAccessAlertNotify = async () => {
    const res = await getUsersAccessAlert({
      unread: true
    });

    setData(res.data?.data?.result || []);
  }

  useEffect(() => {
    getUserAccessAlertNotify();
  }, []);

  return (<>
    <div className={classNames("usersAccessAlertNotify p-1 wc-max ms-auto m-n1 cursor-pointer", data.length && "notify")}>
      <Link to={`${AdminsRoutes.UsersAccessAlert}?startDate=${formatDateYMD(new Date())}`}>
        <BsBellFill />
        {data && data.length ? <span class="notification--num">{data.length}</span> : null}
      </Link>
    </div>
  </>);
};

export default UsersAccessAlertNotify;