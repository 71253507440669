import {
  parseISO,
  format,
  differenceInDays,
  startOfDay,
  endOfDay,
  intervalToDuration,
  formatDuration,
  getDay
} from 'date-fns';

/**A Helper to format date */
export class FormatDate {

  static default(/** @type { string }*/date) {
    if (date == null || typeof date == 'undefined' || !date) return 'N/A'
    return format(
      parseISO(date),
      "dd-MM-yyyy' às ' HH:mm'h'",
      { timeZone: 'America/Sao_Paulo' }
    );
  }

  static toDateHour(/** @type { string }*/date) {
    if (date == null || typeof date == 'undefined' || !date) return 'N/A'
    return format(
      parseISO(date),
      "dd-MM-yyyy' às ' HH:mm'h'",
      { timeZone: 'America/Sao_Paulo' }
    );
  }

  static toDateOnly(/** @type { string }*/date, separator = "-") {
    if (date == null || typeof date == 'undefined' || !date) return 'N/A'
    return format(
      parseISO(new Date(date).toISOString()),
      `dd${separator}MM${separator}yyyy`,
      { timeZone: 'America/Sao_Paulo' }
    );
  }

  static differenceInDays(/** @type { string | Date }*/startDate, /** @type { string | Date }*/ endDate = new Date()) {
    if (startDate == null || typeof startDate == 'undefined' || !startDate) return 'N/A'
    return differenceInDays(parseISO(new Date(endDate).toISOString()), parseISO(new Date(startDate).toISOString()));
  }

  static toStartDate(/** @type { string | Date }*/startDate,) {
    return startOfDay(new Date(startDate))
  }

  static toEndDate(/** @type { string | Date }*/endDate,) {
    return endOfDay(new Date(endDate))
  }

  /** `YYYY-MM-DD` --> `DD-MM-YYYY` */
  static toDayMonthYear(/** @type { string }*/date, separator = "-") {
    if (date == null || typeof date == 'undefined' || !date) return 'N/A';
    return date.split(separator).reverse().join(separator);
  }

  static unixToDuration(/** @type { string }*/timeUnix) {
    if (timeUnix == 0) return '00:00:00';
    if (timeUnix == null || typeof timeUnix == 'undefined' || !timeUnix) return 'N/A';

    const hours = Math.floor(timeUnix / 3600);
    const minutes = Math.floor((timeUnix % 3600) / 60);
    const seconds = timeUnix % 60;

    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  static toDateOfBirth(date) {
    if (date == null || typeof date == 'undefined' || !date) return 'N/A'
    return this.toDayMonthYear(this.getDateOnlyFromISO(date));
  }

  static getDateOnlyFromISO(date) {
    if (date == null || typeof date == 'undefined' || !date) return 'N/A'
    return date.split("T", 1)[0];
  }
}

export default FormatDate
