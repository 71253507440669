import { toast } from '../components/Toast'

export const downloadFile = (url, message = 'CSV downloaded') => {
  if (!url) return;
  const element = document.createElement('a')
  element.setAttribute('href', url)

  element.style.display = 'none'
  document.body.appendChild(element)

  element.click()
  document.body.removeChild(element)

  toast(message, 'success')
}

export const downloadXmlFileFromString = (name, xmlString) => {
  if (!xmlString) return;
  const blob = new Blob([xmlString], { type: 'application/xml' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = `${name}.xml`;

  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(link.href);

  toast('Xml baixado com sucesso!', 'success')
}
