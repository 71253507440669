

export const errorTypes = {
  AffiliatesNotFound: "Afiliado não encontrado!",
  PaymentProviderError: "Ocorreu um erro ao obter o provedor de pagamento",
  UserIdentificationError: "É necessário cadastrar seu CPF para essa solicitação",
  CpfValidationError: "Ocorreu um erro ao validar o CPF do usuário",
  PixCpfMustBeSameRegisteredError: "O CPF informado para saque deve ser o mesmo do cadastro",
  WalletNotFound: "Carteira não encontrada",
  // BalanceError: "",
  CpfInvalidError: "O número de CPF não é válido",
  CpfIrregularError: "O número de CPF está irregular",
  CashOutLimitPerDayError: "O limite de solicitação de saque é de 1 por dia",
  TransactionRepeatedInLess10MinutesError: "Transação repetida em menos de 10 minutos.",
  PixKeyDontBelongToDocumentErrorType: "A chave pix não pertence ao mesmo CPF do usuário",
  CashOutMaxValueIs50000ByDocumentPerDayErrorType: "O valor máximo de saque é de R$ 50.000,00 por documento por dia",
  WithdrawDataError: "Ocorreu um erro ao gerar os dados de saque",
  ThereIsAPendingWithdrawRequestError: "Já existe uma solicitação de saque pendente.",
  CpfAlreadyExistsErrorType: "Já existe um affiliado cadastrado com esse CPF",
  AffiliateStatusPendindErrorType: "Afiliado com status pendente, para atualizá-lo, primeiro você deve aprová-lo",
  AffiliatesStatusPendindNotFound: "Afiliado não encontrado com status pendente!",
  InternalServerError: "Erro Interno no Servidor",
  UserIsAlreadySuspendedErrorType: "Usuário já está suspenso",
  UserIsNotSuspendedErrorType: "Usuário não está suspenso!",
  SignXmlXpathNotFoundErrorType: "Algum dos arquivos xml não pôde ser assinado porque o xpath não foi encontrado, isso pode ocorrer por causa de divergência no nome dos elementos xml.",
  Sigap405UserNotAuthorizedErrorType: "Falha na autorização: Certificado Digital não autorizado para envio de documentos no Sigap-Recepção.",
  SigapMaximumFileSizeExceededErrorType: "Tamanho máximo do arquivo permitido é 3mb",
  Sigap500FalhaNaoTratadaErrorType: "Sigap Api: 500 - Falha não tratada, ERR_999_FALHA_NAO_TRATADA",
  SigapHasRecordOfTheReferenceDateErrorType: "Já existe um registro dessa marca com a data de referência",
  SigapSignatureValidationFailedErrorType: "Sigap Api: 400 - Falha na validação da assinatura do lote. Verifique o processo de assinatura do arquivo.",
  SigapsSendingParameterDifferentFromTypeErrorType: "Parâmentro de envio inválido para o tipo OPERADOR_MENSAL. Verifique os parâmetros disponíveis para envio correto do arquivo",
  CommandFailedXmlSec1ErrorType: "Erro ao executar comando xmlsec1",
  CommandFailedXmlSec1FailedToLoadPkcs12KeyErrorType: "Erro ao executar comando xmlsec1: Falha ao carregar chave pkcs12 ou arquivo p12",
  SigapXmlValidationFailedErrorType: "Sigap Api: 400 - Falha na validação do esquema XML.",
  SigapLoteDuplicateErrorType: "Sigap Api: 400 - Lote duplicado, os dados de identificação do lote já existem em nosso sistema.",
  SigapRequestForbiddenByAdministrativeRulesErrorType: "Sigap Api: 403 - Solicitação proibida por normas administrativas.",
}

export const resolveErrors = (/**@type {{ name: string, description: String, errorCode: number }[]}*/ errors, /** @type { string }*/alternativeMessage) => {
  return errorTypes[errors[0].name] ?? alternativeMessage ?? errors[0]?.description
}