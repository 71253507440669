import { useFormikContext } from "formik";
import React from "react";

export const FormikErrorMessage = ({ name, message, className, showIfEmpty = false, ...props }) => {
  const { values, errors, touched } = useFormikContext();
  return (
    (values.hasOwnProperty(name) || showIfEmpty) && errors[name] ?
      <small className={`text-danger mt-1 d-block ${className ?? ""}`} {...props}>
        {message ?? errors[name]}
      </small>
      : null
  )
}