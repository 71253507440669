import { ErrorMessage, Formik, Form as FormFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Button from "../../../../components/Button";
import CurrencyInput from "../../../../components/CurrencyInput";
import { SigapOperatorMonthyForm, SigapOperatorMonthlyInitialValues } from "../form.schema";
import { resolveErrors } from "../../../../utils/errorTypes";
import FormatNumber from "../../../../utils/formatNumber";
import { sigapService } from "../../../../utils/apiCalls";
import { toast } from "../../../../components/Toast";
import SigapOperatorMonthlyReportTable from "./OperatorMonthlyReportTable";

export const RegisterOperatorMonthlyValues = ({ marcas }) => {
  const [loading, setLoading] = useState(false);

  const ListRef = useRef();

  const onSubmit = async (formValues, { resetForm }) => {
    setLoading(true);
    try {
      await sigapService.registerOperatorMonthyValues(formValues);
      resetForm();
      toast("Salvo com sucesso!", "success");
      if (ListRef && ListRef.current) ListRef.current.reload();
    } catch (err) {
      toast(resolveErrors(err?.response?.data?.errors, `Error ao salvar registro de operação mensal`), "error");
    } finally {
      setLoading(false);
    }
  }

  return (<>
    <div className="TableBg mt-4 pt-1">
      <div className="section-header-wrap m-0 mt-2 py-3 px-3">
        <h2 className="title-text">Registrar valores de operações mensais</h2>
      </div>
      <div className='position-relative h-100 p-3'>
        <Formik
          initialValues={SigapOperatorMonthlyInitialValues}
          validationSchema={SigapOperatorMonthyForm()}
          onSubmit={onSubmit}
        >
          {({ values, handleChange, setFieldValue, handleSubmit, is }) => {
            return (
              <FormFormik autoComplete="off">
                <Row>
                  <Col xs={12} md={6} className="mb-3">
                    <Form.Label>Marca</Form.Label>
                    <Form.Select
                      name="sigapOperatorId"
                      onChange={handleChange}
                      value={values.sigapOperatorId || ""}
                    >
                      <option disabled selected value={''}>Selecione a Marca</option>
                      {marcas?.result?.map(({ name, sigapOperatorId }, key) => {
                        return <option key={key} value={sigapOperatorId}>{name}</option>
                      })}
                    </Form.Select>
                    <ErrorMessage
                      component="small"
                      name={"sigapOperatorId"}
                      className="text-danger"
                    />
                  </Col>
                  <Col xs={12} md={6} className="mb-3">
                    <Form.Label>Data do movimento de referência</Form.Label>
                    <Form.Control
                      type="date"
                      name="dataMovimentoRef"
                      onChange={handleChange}
                      value={values.dataMovimentoRef || ""}
                    />
                    <Form.Control.Feedback className="d-block text-muted">
                      Data do movimento em que os registros foram efetivados no sistema do agente operador de apostas
                    </Form.Control.Feedback>
                    <ErrorMessage
                      component="small"
                      name={"dataMovimentoRef"}
                      className="text-danger"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className="mb-3">
                    <Form.Label>Valor GGR Total Operador</Form.Label>
                    <CurrencyInput
                      name={"ggrTotal"}
                      defaultValue={FormatNumber.currency(values.ggrTotal)}
                      onNumberFormat={(event) => {
                        setFieldValue('ggrTotal', event.detail.number)
                      }}
                    />
                    <Form.Control.Feedback className="d-block text-muted">
                      Corresponte ao valor do GGR referente a todas as apostas do agente operador no período. Corresponde ao valor das apostas em referência, descontados os prêmios pagos aos apostadores.
                    </Form.Control.Feedback>
                    <ErrorMessage
                      component="small"
                      name={"saldoTotalContTrans"}
                      className="text-danger"
                    />
                  </Col>
                  <Col xs={12} className="mb-3">
                    <Form.Label>Média de Tempo de Acesso Diário</Form.Label>
                    <Form.Control
                      type="number"
                      min={0}
                      step="2"
                      name={"mediaTempoAcessoDiario"}
                      value={values.mediaTempoAcessoDiario}
                      onChange={({ target: { value } }) => {
                        setFieldValue('mediaTempoAcessoDiario', Number(value))
                      }}
                    />
                    <Form.Control.Feedback className="d-block text-muted">
                      Média de tempo total de acesso diário dos apostadores no sistema de apostas do agente operador de apostas no período de referência.
                    </Form.Control.Feedback>
                    <Form.Control.Feedback className="d-block alert p-1 px-2 alert-warning">
                      Informar o tempo em timeunix / segundos, Ex: 90061 em timeunix resultará em 25:01:01
                    </Form.Control.Feedback>
                    <ErrorMessage
                      component="small"
                      name={"mediaTempoAcessoDiario"}
                      className="text-danger"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button className={"ms-auto d-block"} onClick={handleSubmit} loading={loading} >
                      Salvar
                    </Button>
                  </Col>
                </Row>
              </FormFormik>
            )
          }}
        </Formik>
      </div>
    </div>
    <SigapOperatorMonthlyReportTable ref={ListRef} />
  </>)
};

export default RegisterOperatorMonthlyValues;